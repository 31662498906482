import { primaryPadding } from 'constants/styles';
import styled from 'styled-components';
import { AbsoluteLocation, IsClosed, Sizes, ZIndex } from 'types';

interface Props extends AbsoluteLocation, IsClosed, Sizes, ZIndex {}

export const AbsoluteWrapper = styled.div<Props>`
    position: absolute;
    ${({ top, bottom }) => (top ? `top: ${top}` : bottom ? `bottom: ${bottom}` : `top: ${primaryPadding}`)};
    ${({ left, right }) => (left ? `left: ${left}` : right ? `right: ${right}` : `left: ${primaryPadding}`)};
    ${({ width }) => (width ? `width: ${width}` : '')};
    ${({ height }) => (height ? `height: ${height}` : '')};
    display: ${({ isClosed }) => (isClosed ? 'none' : 'block')};
    ${({ zIndex }) => (zIndex ? `z-index: ${zIndex}` : '')};
`;
