import React, { FC } from 'react';
import { Stub } from 'components/common/Stub';
import { VideoWrapper } from './styles';

export const ContentLayout: FC = ({ children }) => (
    <VideoWrapper>
        <Stub />
        {children}
    </VideoWrapper>
);
