import { productImgDiameter } from 'components/layouts/cards/CardProduct/constants';
import styled from 'styled-components';
import { ellipsisMixin, flexCenter, fonts, secondaryPadding } from 'constants/styles';

// export const CardProductStyled = styled.div`
//     display: flex;
//     flex-direction: column;
//     min-height: 60px;
//     width: 100%;
// `;

// export const Image = styled.img`
//     width: ${productImgDiameter};
//     height: ${productImgDiameter};
//     flex-shrink: 0;
//     border-radius: 10px;
//     margin-right: ${secondaryPadding};
// `;

export const ProductWrapper = styled.div`
    background: #ededed;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    padding: 10px;
`;

export const ProductTitleText = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.023333340883255005px;
    text-align: center;
    //margin-bottom: ${secondaryPadding};
`;

export const ProductNameText = styled.span`
    width: 100%;
    ${ellipsisMixin};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
`;

export const ProductDescriptionText = styled.span`
    width: 100%;
    ${ellipsisMixin};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 15px;
`;

export const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Wrap = styled.div`
    margin-bottom: 10px;
`;

export const Title = styled.h4`
    margin: 0;
    font-family: ${fonts.sfuidBold};
    font-size: 15px;
`;

export const Description = styled.p`
    // height: ${productImgDiameter};
    font-family: ${fonts.sfuidBold};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
    overflow: hidden;
`;

export const StyledRow = styled.div`
    ${flexCenter};
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`;

export const ButtonText = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.16470590233802795px;
    text-align: left;
`;

// export const ButtonStyled = styled(Button)`
//     //align-self: flex-end;
//     //height: 28px;
// `;
