import ImgAppStore from 'assets/img/appStore.png';
import ImgGooglePlay from 'assets/img/googlePlay.png';
import LogoImg from 'assets/img/logo.png';
import { androidAppStoreLink, iosAppStoreLink } from 'constants/routes';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { searchEvents } from 'stores/search';
import { handleClickAppStoreApp, handleClickGooglePlayApp } from 'utils/googleTagManagerEvents';
import {
    Container,
    HeaderStyled,
    ImageAppStore,
    ImageGooglePlay,
    Link,
    Logo,
    LogoLink,
    RightContent,
    Text
} from './styles';

export const Header = () => {
    const handleClick = () => searchEvents.setDefaultHashtag();
    return !isMobile ? (
        <HeaderStyled>
            <Container>
                <LogoLink to="/" onClick={handleClick}>
                    <Logo src={LogoImg} />
                </LogoLink>
                <RightContent>
                    <Text>Download the App for free</Text>
                    <Link href={iosAppStoreLink} target="_blank" onClick={handleClickAppStoreApp}>
                        <ImageAppStore src={ImgAppStore} />
                    </Link>
                    <Link href={androidAppStoreLink} target="_blank" onClick={handleClickGooglePlayApp}>
                        <ImageGooglePlay src={ImgGooglePlay} />
                    </Link>
                </RightContent>
            </Container>
        </HeaderStyled>
    ) : null;
};
