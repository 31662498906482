import styled from 'styled-components';
import { desktopHeaderHeight } from 'components/common/Header/constants';

export const HomeStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 1200px;
    height: calc(100vh - ${desktopHeaderHeight});
    //TODO width
    margin: 0 auto;
`;
