import React, { useEffect, useState } from 'react';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { Hashtag } from 'components/common/Hashtag';
import { useStore } from 'effector-react';
import { trendingsStores } from 'stores/trendings';
import { BlockTitle } from 'components/common/typography/BlockTitle';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { ScrolledBlock } from 'components/common/blocks/ScrolledBlock';
import { grey6, secondaryPadding } from 'constants/styles';
import { HashtagsWrapper } from './styles';
import { wrapperPadding } from 'components/modals/MobileProductModal/constants';
import { ScrollButtons } from 'components/common/ScrollButtons';
import { isMobile } from 'react-device-detect';
import { TrendingTitle } from 'components/common/ModalProducts/styles';
import { WrapperHashtagLine } from 'components/common/Hashtag/styles';
import { Size } from 'calculate-size';
import { calculateHashtagsSliceIndex } from 'utils/calculators';

interface Props extends Partial<Pick<Size, 'width'>> {}

export const TrendingHashtags = ({ width }: Props) => {
    const isAllTrendingHashtagsVisible = useStore(trendingsStores.isAllTrendingHashtagsVisible);
    const hashtags = useStore(trendingsStores.hashtags);
    const [sliceIndex, setSliceIndex] = useState(1);
    // const trendingHashtagsScrollWidth = useStore(trendingsStores.trendingHashtagsScrollWidth);
    // const trendingHashtagsPercentageScroll = useStore(trendingsStores.trendingHashtagsPercentageScroll);

    useEffect(() => setSliceIndex(calculateHashtagsSliceIndex(width || 0, hashtags)), [width, hashtags]);

    if (isMobile) {
        return (
            <>
                {isAllTrendingHashtagsVisible ? (
                    <Row alignCenter justifyBetween>
                        <MarginWrapper marginBottom="15px" marginLeft={wrapperPadding}>
                            <BlockTitle>Trending hashtags</BlockTitle>
                        </MarginWrapper>
                        <MarginWrapper marginRight="27px">
                            {/*<Image src={moreImg} />*/}
                            {/*<TrendingScroll*/}
                            {/*    percentageScroll={trendingHashtagsPercentageScroll}*/}
                            {/*    width={trendingHashtagsScrollWidth + 'px'}*/}
                            <ScrollButtons typeScrolledLine="hashtag" />
                            {/*/>*/}
                        </MarginWrapper>
                    </Row>
                ) : (
                    <MarginWrapper marginBottom="15px" marginLeft={wrapperPadding}>
                        <BlockTitle>Trending hashtags</BlockTitle>
                    </MarginWrapper>
                )}
                <HashtagsWrapper>
                    <ScrolledBlock background={grey6} height="63px" typeScrolledLine="hashtag">
                        {hashtags.map(({ tag }, i) => (
                            <Hashtag key={i.toString()} hasPrefix fontWeight={600}>
                                {tag}
                            </Hashtag>
                        ))}
                    </ScrolledBlock>
                </HashtagsWrapper>
            </>
        );
    } else {
        return (
            <>
                <TrendingTitle>Trending hashtags</TrendingTitle>
                <Section marginBottom={secondaryPadding}>
                    <WrapperHashtagLine>
                        {hashtags.slice(0, sliceIndex).map(({ tag }, i) => (
                            <Hashtag key={i.toString()} hasPrefix>
                                {tag}
                            </Hashtag>
                        ))}
                    </WrapperHashtagLine>
                    <WrapperHashtagLine>
                        {hashtags.slice(sliceIndex).map(({ tag }, i) => (
                            <Hashtag key={i.toString()} hasPrefix>
                                {tag}
                            </Hashtag>
                        ))}
                    </WrapperHashtagLine>
                </Section>
            </>
        );
    }
};
