import { flexCenter, fonts, tablet } from 'constants/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.header`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #fbfaff;
    border-bottom: 1px solid #f1f1fa;
    z-index: 100;
`;

export const Container = styled.div`
    width: 100%;
    max-width: calc(1024px + 32px * 2);
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    @media (max-width: ${tablet}) {
        max-width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
`;

export const ExternalLink = styled.a`
    ${flexCenter};
    background-color: #f1f1fa;
    padding: 10px 15px;
    border-radius: 32px;
    transition: background-color 0.2s linear;

    &:hover {
        background-color: #e7e6f0;
    }

    &:active {
        background-color: #c4c4cd;
    }
`;

export const Title = styled.h2`
    font-family: ${fonts.poppins};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #27272e;
    margin: 0;
    text-align: right;
`;
