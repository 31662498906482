import styled from 'styled-components';

export const Title = styled.h3`
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
`;

export const Text = styled.p`
    font-size: 16px;
    line-height: 24px;
    max-width: 384px;
    text-align: center;
    margin: 0;
    margin-bottom: 24px;
`;
