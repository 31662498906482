import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { disableDefaultButtonStyleMixin, flexCenter, fonts, tablet } from 'constants/styles';
import ReactHlsPlayer from 'react-hls-player';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100vh - 60px);
    position: relative;
    overflow: hidden;

    @media (min-width: ${tablet}) {
        border-radius: 24px;
        height: 654px;
    }
`;

export const BgWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    ${flexCenter};
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
`;

export const HlsPlayer = styled(ReactHlsPlayer)`
    object-fit: cover;
`;

const absoluteMixin = css`
    width: 100%;
    flex-wrap: nowrap;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
`;

export const ControlsWrapper = styled(Column)`
    ${absoluteMixin};
    bottom: 0;
    padding: 24px 16px;
`;

export const MobileControls = styled(Column)`
    ${absoluteMixin};
    top: 0;
    padding: 8px 24px;
`;

export const ButtonWrapper = styled(Column)`
    ${absoluteMixin};
    bottom: 0;
    padding: 24px;
`;

export const IconButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    ${flexCenter};
`;

export const DurationText = styled.span`
    font-family: ${fonts.sfuidSemibold};
    font-size: 12px;
    line-height: 18px;
    color: #fbfaff;
`;

export const UserWrapper = styled(Row)`
    background: rgba(39, 39, 46, 0.2);
    border-radius: 23px;
    padding: 4px 14px 4px 8px;
    transform: translate(14px);
    font-size: 12px;
    line-height: 18px;
    font-family: ${fonts.sfuidSemibold};
    color: #fbfaff;
`;
