import { ButtonProps } from 'components/common/buttons/Button/types';
import { colors, flexCenter, fonts } from 'constants/styles';
import styled from 'styled-components';

interface Props extends ButtonProps {}

export const StyledButton = styled.button<Props>`
    ${flexCenter};
    height: ${p => p.height || '25px'};
    ${({ width }) => (width ? `width: ${width};` : ``)};
    outline: none;
    color: ${colors.black};
    background-color: ${({ bgColor }) => bgColor && colors[bgColor]};
    border-radius: 100px;
    border: none;
    font-family: ${fonts.sfuidSemibold};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.16470590233802795px;
    text-align: left;
    padding: 0 25px;
    white-space: nowrap;
    cursor: pointer;
`;

// export const Text = styled.span`
//     font-size: 12px;
// `;
