import Stub from 'assets/img/stub.png';
import {
    colors,
    flexStart,
    primaryPadding,
    scrollBarDesktopMixin,
    scrollBarMobileMixin,
    secondaryPadding
} from 'constants/styles';
import styled, { css } from 'styled-components';

export const RelatedVideoStyled = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: auto;
`;

export const User = styled.div`
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const UserImage = styled.img`
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
`;

export const Title = styled.h3`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    margin-bottom: ${secondaryPadding};
`;

export const UserDescription = styled.p`
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    color: ${colors.white};
`;

export const UserInfo = styled.div`
    text-align: center;
`;

export const UserName = styled.div`
    font-size: 20px;
    text-transform: uppercase;
    color: ${colors.white};
`;

interface ContentProp {
    isItemsCreator?: boolean;
}

export const Content = styled.div<ContentProp>`
    padding: 0 ${primaryPadding};
    width: 100%;

    ${scrollBarDesktopMixin};

    ${p =>
        p.isItemsCreator &&
        css`
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            height: 100%;
        `}
`;

export const Wrap = styled.div`
    ${flexStart};
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;

// export const StyledLink = styled(Link)`
//     position: relative;
//     //margin-left: 5px;
//     //margin-top: 10px;
//     display: flex;
//     //align-items: center;
//     //justify-content: center;
//     //width: calc(50% - 10px);
//     //overflow: hidden;
//     border-radius: 13px;
//     //background-color: ${colors.black};
// `;

// export const Image = styled.img`
//     width: 100%;
// `;

export const Watching = styled.div`
    position: absolute;
    bottom: 6px;
    left: 6px;
    display: flex;
    align-items: center;
`;

interface IUserWrap {
    default: boolean;
}

export const UserWrap = styled.div<IUserWrap>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border: 2px solid ${colors.white};
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 50%;

    ${p =>
        p.default &&
        css`
            background-image: url(${Stub});
        `}
`;

export const UserImageWatch = styled.img`
    width: 100%;
    height: 100%;
`;

export const Watch = styled.div`
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
`;

export const WatchIconStyled = styled.img`
    width: 14px;
    height: 14px;
    border-radius: 50%;
`;

export const WatchText = styled.p`
    margin: 0;
    margin-left: 6px;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
`;

export const MobileVideoWrapper = styled.div`
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    align-items: flex-start;
    ${scrollBarMobileMixin};
`;
