import { localeStandard } from 'constants/services';
import { createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { API } from 'services';
import { createNotifyingEffect, retrieveLocale } from 'utils/common';
import { v4 as uuid4 } from 'uuid';

const userData = connectLocalStorage('WebViewerUser').onError(err => console.log(err));
const sessionData = connectLocalStorage('WebViewerSession').onError(err => console.log(err));

const updateInitialLoading = createEvent();
const initialLoading = createStore(false).on(updateInitialLoading, state => !state);

const loadToken = createNotifyingEffect({
    handler: async () =>
        await API.user.createAccountAnonymous({ locale: retrieveLocale(), localeStandard: localeStandard })
});

export const getUserProfile = createNotifyingEffect({
    handler: async ({ userId }: YEAY.GetUserProfileRequest) => {
        try {
            updateInitialLoading();
            const data = await API.user.getProfile({ userId });
            updateInitialLoading();

            return data || {};
        } catch (error) {
            updateInitialLoading();
            return {};
        }
    }
});

const user = createStore<YEAY.UserJwtTokenResponse>(userData.init({})).on(loadToken.doneData, (_, user) => user);
const sessionId = createStore<string>(sessionData.init(uuid4()));

const userProfile = createStore<YEAY.GetUserProfileResponse>({}).on(getUserProfile.doneData, (_, newState) => newState);
user.watch(userData);
sessionId.watch(sessionData);

user.watch(userData);
sessionId.watch(sessionData);

const userEffects = { loadToken, getUserProfile };
const userStores = { user, sessionId, initialLoading, userProfile };

export { userEffects, userStores };
