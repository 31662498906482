import {
    removeImgDiameter,
    removeImgMarginRight,
    wrapperHorizontalPadding,
    wrapperMargin
} from 'components/common/Hashtag/constants';
import { calculateHashtagWidth } from 'components/common/ModalProducts/constants';
import { defaultScrollWidth } from 'components/common/TrendingScroll/constants';
import { avatarDiameter, marginRightTrendingCard } from 'components/layouts/cards/TrendingCreatorCard/constants';
import { yeayappDeepLink } from 'constants/routes';
import { localStorageName } from 'constants/services';
import { milliseconds } from 'constants/special';
import { primaryPadding } from 'constants/styles';
import { createEffect, Effect } from 'effector';
import { isAndroid, isIOS } from 'react-device-detect';
import { Hashtags } from 'types';

/**
 * Create effect with bad response notifications
 */
interface BadResponseErrors
    extends YEAY.Error409ConflictResponse,
        YEAY.Error400BadRequest,
        YEAY.Error404NotFoundResponse {}

export function createNotifyingEffect<Params, Done>(config: {
    name?: string;
    handler?: (params: Params) => Promise<Done> | Done;
    sid?: string;
}): Effect<Params, Done> {
    const effect = createEffect(config);
    effect.fail.watch(({ error }: { error: BadResponseErrors }) => {
        if (!error.isSuccess) {
            // effects.setNotification({
            //     message: error.message || '',
            //     description: ''
            // });
        }
    });

    return effect;
}

export const noop = () => {};
export const retrieveLocale = () => (navigator?.language?.length === 5 ? navigator.language : 'en-US');

// wait 5 sec.
export const wait = (time = 5000) =>
    new Promise(resolve => {
        setTimeout(resolve, time);
    });

export const getCountTimersForEventVideo = (duration: number) => Math.ceil(duration / 5) - 1;

export const getVideoFromLocalStorage = (): string[] =>
    JSON.parse(localStorage.getItem(localStorageName) as string) || [];

export const addIdVideoToLocalStorage = (id: string) => {
    let registerEventStorage = getVideoFromLocalStorage();
    registerEventStorage.push(id);
    localStorage.setItem(localStorageName, JSON.stringify(registerEventStorage));
};

export const checkVideoInLocalStorage = (id: string): boolean => getVideoFromLocalStorage().includes(id);
export function mergeGenericHashtagResponse<T extends { id?: string }>(state: T[], items: T[] | undefined): T[] {
    const merge = [...state, ...(items || [])];
    let mergedMap = new Map<string | undefined, T>();
    merge.forEach(item => mergedMap.set(item.id, item));
    let result: T[] = [];
    // @ts-ignore
    for (let value of mergedMap.values()) result.push(value);
    return result;
}

export const lengthLineTrendingHashtags = (hashtags: YEAY.GetTrendingTagResponse[]) =>
    hashtags.reduce(
        (sum, { tag }) =>
            sum +
            (calculateHashtagWidth(tag as string, '600') +
                parseInt(wrapperHorizontalPadding) * 2 +
                parseInt(wrapperMargin)),
        0
    );

export const isAllHashtagsVisible = (hashtags: YEAY.GetTrendingTagResponse[], width: number) =>
    lengthLineTrendingHashtags(hashtags) > width;

export const calculateTrendingHashtagsScrollWidth = (hashtags: YEAY.GetTrendingTagResponse[], width: number) =>
    Math.round((width * parseInt(defaultScrollWidth)) / lengthLineTrendingHashtags(hashtags));

export const lengthLineTrendingCreators = (users: YEAY.GetTrendingUserResponse[]) =>
    users.reduce(sum => sum + parseInt(avatarDiameter) + parseInt(marginRightTrendingCard), 20);

export const isAllCreatorsVisible = (users: YEAY.GetTrendingUserResponse[], width: number) =>
    lengthLineTrendingCreators(users) > width;

export const calculateTrendingCreatorsScrollWidth = (users: YEAY.GetTrendingUserResponse[], width: number) =>
    Math.round((width * parseInt(defaultScrollWidth)) / lengthLineTrendingCreators(users));

export const countHashtagsLineQuantity = (hashtags: Hashtags[], width: number) => {
    let lineQuantity = 1;
    let sumLengthLine = 0;
    const availableWidth = width - 2 * parseInt(primaryPadding);
    if (hashtags.length === 0) return 0;
    hashtags.forEach(({ value }) => {
        const hashtagsLength =
            calculateHashtagWidth(value, '600') +
            parseInt(removeImgDiameter) +
            parseInt(removeImgMarginRight) +
            parseInt(wrapperHorizontalPadding) * 2 +
            parseInt(wrapperMargin);
        sumLengthLine += hashtagsLength;
        if (sumLengthLine > availableWidth) {
            lineQuantity++;
            sumLengthLine = hashtagsLength;
        }
    });
    return lineQuantity;
};

export const findElementInChildrenList = (targetElement: Element, searchElement: EventTarget | null) => {
    let isInParentBlock = false;
    const checkChildrenRef = (el: Element) => {
        if (el.childElementCount === 0) return;
        else
            el.childNodes.forEach((el: any) => {
                if (searchElement === el) isInParentBlock = true;
                checkChildrenRef(el);
            });
    };

    checkChildrenRef(targetElement);
    return isInParentBlock;
};

export const setAppLink = (pathName: string, search: string) => {
    setTimeout(function () {
        if (isIOS || isAndroid) {
            // @ts-ignore
            window.location = yeayappDeepLink + pathName + search;
        }
    }, milliseconds);
};

export const goToExternalLink = (href?: string | null) => {
    if (!href) return;

    window.open(href);
};
