import Stub from 'assets/img/stub.png';
import { colors, fonts, secondaryPadding } from 'constants/styles';
import styled, { css } from 'styled-components';

interface UserStyledProps {
    isVideo: boolean;
}

export const Name = styled.span`
    font-family: ${fonts.sfproSemibold};
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${colors.black};
`;

interface ImageWrapProp {
    default: boolean;
}

export const ImageWrapper = styled.div<ImageWrapProp>`
    margin-right: ${secondaryPadding};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: 2px solid ${colors.white};
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 50%;

    ${p =>
        p.default &&
        css`
            background-image: url(${Stub});
        `}
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const UserStyled = styled.div<UserStyledProps>`
    display: flex;
    z-index: 1;
    align-items: center;

    ${p =>
        p.isVideo &&
        css`
            flex-direction: row-reverse;
            ${ImageWrapper} {
                margin-left: 10px;
                width: 30px;
                height: 30px;
            }

            ${Name} {
                font-size: 14px;
                font-weight: normal;
                text-transform: none;
                color: ${colors.white};
            }
        `}
`;
