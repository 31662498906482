import { isMobile } from 'react-device-detect';

export const avatarDiameter = '40px';
export const verifiedIconDiameter = '12px';

export const defaultDesktopCardWidth = '370px';
export const defaultDesktopCardHeight = '100px';

export const defaultMobileCardWidth = '100%';
export const defaultMobileCardHeight = avatarDiameter;

export const defaultCardWidth = isMobile ? defaultMobileCardWidth : defaultDesktopCardWidth;
