import React from 'react';
import { CustomImg } from 'components/common/CustomImg';
import verifiedIcon from 'assets/img/verifedIcon.svg';
import { Row } from 'components/grid/wrappers/FlexWrapper';
import { Diameter } from 'types/interfaces';

export interface VerifiedIconProps extends Diameter {}

export const VerifiedIcon = ({ diameter }: VerifiedIconProps) => (
    <Row>
        <CustomImg alt="verifiedIcon" height={diameter} src={verifiedIcon} width={diameter} />
    </Row>
);
