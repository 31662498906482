import React, { FC } from 'react';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import verifiedIcon from 'assets/img/verifedIcon.svg';
import { RelativeWrapper } from 'components/grid/wrappers/RelativeWrapper';
import { Img } from 'components/common/imageComponents/Image/styles';
import { AbsoluteLocation } from 'types';

interface AvatarLayout extends Pick<YEAY.PlaylistSingleVideoResponse, 'isTrusted'>, Pick<AbsoluteLocation, 'right'> {}

export const AvatarLayout: FC<AvatarLayout> = ({ children, isTrusted, right }) => (
    <RelativeWrapper height="auto" width="auto">
        {children}
        {isTrusted && (
            <AbsoluteWrapper bottom="0" right={right || '3px'}>
                <Img src={verifiedIcon} />
            </AbsoluteWrapper>
        )}
    </RelativeWrapper>
);
