import { secondaryPadding } from 'constants/styles';
import { pixelsSubtraction } from 'utils/parsers';

export const videoFooterWidth = `calc(100% - 2 * ${secondaryPadding})`;

export const primaryProductBorderRadius = '9px';
export const primaryProductBorderWidth = '2px';

export const engagementImgDiameter = '14px';
export const primaryProductWidth = '70px';
export const primaryProductHeader = '48px';
export const primaryProductFooter = pixelsSubtraction(primaryProductWidth, primaryProductHeader);

export const bagImgWidth = '10px';
export const bagImgHeight = '12px';

export const PrimaryProductTextFontSize = '10px';
