import { primaryPadding } from 'constants/styles';

export const prefix = '#';

export const wrapperHeight = '30px';
export const wrapperBorderRadius = '15px';
export const wrapperMargin = primaryPadding;
export const wrapperHorizontalPadding = wrapperMargin;
export const removeImgDiameter = '14px';
export const removeImgMarginRight = '8px';
