import { RefObject, useEffect, useState } from 'react';

// * any because html element can be any
export const useRefPosition = (ref: RefObject<any>): { x: number; y: number } => {
    const [selectPosition, setSelectPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (!ref.current) return;
        const { x, y } = ref.current.getBoundingClientRect();
        setSelectPosition({ x, y });
    }, [ref]);

    return selectPosition;
};
