import phoneImage from 'assets/img/open-in-phone.png';
import { Button } from 'components/common/buttons/Button';
import { Image } from 'components/common/imageComponents/Image';
import { Column } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { androidAppStoreLink, iosAppStoreLink } from 'constants/routes';
import React, { FC } from 'react';
import { isIOS } from 'react-device-detect';
import { goToExternalLink } from 'utils/common';
import { handleClickAppStoreApp, handleClickGooglePlayApp } from 'utils/googleTagManagerEvents';
import { Text, Title } from './styles';

interface Props {
    description?: string;
}

export const MobileWarning: FC<Props> = ({ description = 'We can’t wait to see you on YEAY!' }) => {
    const handleDownloadClick = () => {
        if (isIOS) {
            handleClickAppStoreApp();
        } else {
            handleClickGooglePlayApp();
        }

        goToExternalLink(isIOS ? iosAppStoreLink : androidAppStoreLink);
    };

    return (
        <Column alignCenter justifyCenter>
            <MarginWrapper marginBottom="32px">
                <Image height="60px" src={phoneImage} width="60px" />
            </MarginWrapper>
            <Title>Open link on mobile</Title>
            <Text>{description}</Text>
            <Button bgColor="green" onClick={handleDownloadClick}>
                Download the app
            </Button>
        </Column>
    );
};
