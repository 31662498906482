import styled, { css } from 'styled-components';
import { Sizes } from 'types/interfaces';

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
`;

export const Loading = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 225px;
    height: 225px;
    transform: translate(-50%, -50%);
`;

export const Text = styled.span`
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: 0.3px;
    text-align: center;
`;

interface WrapperProp extends Sizes {
    background?: string;
    padding?: string;
    isHomePage?: boolean;
}

export const Wrapper = styled.div<WrapperProp>`
    width: ${p => `${p.width}`};
    height: ${p => `${p.height}`};
    background: ${p => p.background};
    overflow: auto;
    ${({ padding }) => padding && `padding: 0 ${padding}`};

    ${({ isHomePage }) =>
        isHomePage &&
        css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        `};

    .swiper-container {
        height: 100%;
        width: 100%;
    }

    .swiper-container-vertical > .swiper-scrollbar {
        display: none;
    }
`;
