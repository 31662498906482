import { MobileWarning } from 'components/common/blocks/MobileWarning';
import { MainLayout } from 'components/layouts/MainLayout';
import { Wrapper } from 'pages/Referral/styles';
import React from 'react';

export const Referral = () => (
    <MainLayout>
        <Wrapper>
            <MobileWarning description="Hey 👋 Please open the referral link on a mobile device. We can’t wait to see you on YEAY!" />
        </Wrapper>
    </MainLayout>
);
