export const fonts = {
    sfproSemibold: 'SFProDisplay SemiBold, sans-serif',
    sfproRegular: 'SFProDisplay Regular, sans-serif',
    sfuidSemibold: 'SFUIDisplay SemiBold, sans-serif',
    sfuidBold: 'SFUIDisplay Bold, sans-serif',
    poppins: 'Poppins SemiBold, sans-serif',
    sfUi: 'SfUiText Regular, sans-serif'
};

export type Fonts = typeof fonts;
export type Font = keyof typeof fonts;
