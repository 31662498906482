import React from 'react';
import { Image, ImageWrapper, Name, UserStyled } from 'components/layouts/cards/UserCard/styles';
import { AvatarLayout } from 'components/layouts/AvatarLayout';
import { verifiedIconMarginRight } from 'components/layouts/cards/UserCard/constants';
import { isMobile } from 'react-device-detect';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { ModalProductTitle } from 'components/modals/MobileProductModal/styles';
import { useStore } from 'effector-react';
import { playlistStores } from 'stores/playlist';

export const UserCard = () => {
    const { profileImageUrl, username, isTrusted } = useStore(playlistStores.mainVideo);

    if (isMobile) {
        return (
            <>
                <AvatarLayout isTrusted={isTrusted}>
                    <AvatarImg diameter="50px" src={profileImageUrl} />
                </AvatarLayout>
                <ModalProductTitle marginLeft="16px">{username}</ModalProductTitle>
            </>
        );
    } else {
        if (username) {
            return (
                <UserStyled isVideo={false}>
                    <AvatarLayout isTrusted={isTrusted} right={verifiedIconMarginRight}>
                        <ImageWrapper default={!profileImageUrl}>
                            {profileImageUrl && <Image alt="user-icon" src={profileImageUrl} />}
                        </ImageWrapper>
                    </AvatarLayout>
                    <Name>{username}</Name>
                </UserStyled>
            );
        } else return null;
    }
};
