import { boundaryNumber } from 'constants/special';

// multiply pixels by number
export const multiplyPixels = (pixels: string, multiplier: number) => {
    const num = Number(pixels.slice(0, -2));
    if (pixels.slice(-2) !== 'px' || Number.isNaN(num)) return '0';

    return num * multiplier + 'px';
};

// addition two pixels
export const pixelsAddition = (pixels1: string, pixels2: string) => {
    const num1 = Number(pixels1.slice(0, -2));
    const num2 = Number(pixels2.slice(0, -2));
    if (pixels1.slice(-2) !== 'px' || Number.isNaN(num1) || pixels2.slice(-2) !== 'px' || Number.isNaN(num2))
        return '0';

    return num1 + num2 + 'px';
};

export const pixelsSubtraction = (pixels1: string, pixels2: string) => {
    const num1 = Number(pixels1.slice(0, -2));
    const num2 = Number(pixels2.slice(0, -2));
    if (pixels1.slice(-2) !== 'px' || Number.isNaN(num1) || pixels2.slice(-2) !== 'px' || Number.isNaN(num2))
        return '0';

    return num1 - num2 + 'px';
};

// replace exact number to approximate number to reduce its length
export const numberConverter: (n: number) => string = n => {
    if (n <= 0 || n === Infinity) return '0';
    if (!Number.isInteger(n)) n = Math.round(n);
    let str = n.toString();
    let i = 0;
    if (n < boundaryNumber)
        while (str.length > 4) {
            i++;
            str = str.substring(0, str.length - 3);
        }
    else
        while (str.length > 3) {
            i++;
            str = str.substring(0, str.length - 3);
        }

    if (i !== 0) {
        if (i === 1) str += 'K';
        else if (i === 2) str += 'M';
        else if (i === 3) str += 'B';
        else str += 'B+';
    }

    return str;
};

export const parseSearchParams = <T>(search: string) =>
    Object.fromEntries(
        search
            .replaceAll('?', '')
            .split('&')
            .map(item => item.split('='))
    ) as T;
