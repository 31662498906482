import React from 'react';
import { StubDescription, StubImage, StubContentWrapper, StubWrapper } from './styled';
import RotatePhone from 'assets/img/rotate-phone.png';

export const Stub = () => (
    <StubWrapper>
        <StubContentWrapper>
            <StubImage alt="rotate-phone" src={RotatePhone} />
            <StubDescription>Rotate your Phone for a better experience</StubDescription>
        </StubContentWrapper>
    </StubWrapper>
);
