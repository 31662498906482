import { desktopHeaderHeight } from 'components/common/Header/constants';
import { colors, fonts } from 'constants/styles';
import { Link as LinkRouter } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderStyled = styled.header`
    height: ${desktopHeaderHeight};
    border-bottom: 2px solid rgba(227, 227, 227, 0.7);
`;

export const Container = styled.div`
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoLink = styled(LinkRouter)`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    width: 90px;
    height: 44px;
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
`;

export const Text = styled.span`
    margin-right: 20px;
    font-family: ${fonts.sfuidBold};
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: ${colors.black};
`;

export const Link = styled.a`
    width: 102px;
    height: 32px;
    border-radius: 30px;
    overflow: hidden;

    &:first-of-type {
        margin-right: 8px;
    }
`;

export const ImageAppStore = styled.img`
    width: 100%;
    height: 100%;
`;

export const ImageGooglePlay = styled.img`
    width: 100%;
    height: 100%;
`;
