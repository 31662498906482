import { Search } from 'components/common/Search';
import { MobileHeader } from 'components/common/videoComponents/VideoHeader';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { HomeLayout } from 'components/layouts/HomeLayout';
import { MainLayout } from 'components/layouts/MainLayout';
import { withAdaptiveComponent } from 'hocs/withAdaptiveComponent';
import { Content, Wrapper } from 'pages/Main/styles';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

// import { Content } from './styles';

interface Props {
    width: number;
    height: number;
    isShowProducts: boolean;
    isRelatedVideo: boolean;
}

const HomeComponent: FC<Props> = ({ width, height }) => (
    <ContentLayout>
        <MobileHeader type="search" />
        <Content>
            <Wrapper isHomePage background="white" height={height + 'px'} padding="10px" width={width + 'px'}>
                <Search />
            </Wrapper>
        </Content>
    </ContentLayout>
);

const AdaptiveHomeComponent = withAdaptiveComponent(HomeComponent, true);

export const Home = () => (
    <MainLayout>
        {isMobile ? (
            <AdaptiveHomeComponent />
        ) : (
            <HomeLayout>
                <Search />
            </HomeLayout>
        )}
    </MainLayout>
);
