import React from 'react';
import { BlockTitle } from 'components/common/typography/BlockTitle';
import { useStore } from 'effector-react';
import { trendingsStores } from 'stores/trendings';
import { TrendingCreatorCard } from 'components/layouts/cards/TrendingCreatorCard';
import { TopCreatorWrapper } from './styles';

import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { topCreatorsCount } from 'constants/stores';
import { userUrl } from 'constants/routes';
import { useHistory } from 'react-router-dom';

export const TopCreatorBlock = () => {
    const users = useStore(trendingsStores.users);
    const history = useHistory();
    return (
        <>
            <MarginWrapper marginBottom="15px">
                <BlockTitle>Top Creators</BlockTitle>
            </MarginWrapper>
            <TopCreatorWrapper>
                {users.slice(0, topCreatorsCount).map(({ userId, username = '', profileImageUrl, isTrusted }) => (
                    <TrendingCreatorCard
                        key={userId}
                        isTrusted={isTrusted}
                        marginRight="0"
                        name={username}
                        src={profileImageUrl}
                        onClick={() => history.push(userUrl + userId)}
                    />
                ))}
            </TopCreatorWrapper>
        </>
    );
};
