import { useStore } from 'effector-react';
import jwtDecode from 'jwt-decode';
import { memo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { DecodedToken } from 'types';
import { userEffects, userStores } from '../stores/user';

export const IdleTimer = memo(() => {
    const user = useStore(userStores.user);
    const token = user.token as string;
    const expiresSec = jwtDecode<DecodedToken>(token).exp;
    const currentMs = new Date().getTime();
    const timeoutExpiresMs = expiresSec * 1000 - currentMs; // в мс.

    useIdleTimer({
        timeout: timeoutExpiresMs,
        onIdle: userEffects.loadToken
    });

    return null;
});
