import { ButtonProps } from 'components/common/buttons/Button/types';
import React, { FC, MouseEvent } from 'react';
import { StyledButton } from './styles';

interface Props extends ButtonProps {
    href?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    className?: string;
}

export const Button: FC<Props> = ({
    href,
    type = 'button',
    bgColor,
    onClick,
    className,
    children,
    disabled,
    height,
    width
}) =>
    href ? (
        <StyledButton
            as="a"
            bgColor={bgColor}
            className={className}
            disabled={disabled}
            height={height}
            href={href}
            target="_blank"
            width={width}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    ) : (
        <StyledButton
            bgColor={bgColor}
            className={className}
            disabled={disabled}
            height={height}
            type={type}
            width={width}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    );
