import { useStore } from 'effector-react';
import React from 'react';
import { notificationsEffects, notificationsStores } from 'stores/notifications';
import { Description, Header, Message, Notification, Remove, StyledNotifications } from './styles';

export const Notifications = () => {
    const notifications = useStore(notificationsStores.notifications);

    if (notifications.length === 0) return null;

    return (
        <StyledNotifications>
            {notifications.map(({ message, description, id }) => (
                <Notification key={id}>
                    <Header>
                        <Message>{message}</Message>
                        <Remove onClick={() => notificationsEffects.removeNotification(id)} />
                    </Header>
                    <Description>{description}</Description>
                </Notification>
            ))}
        </StyledNotifications>
    );
};
