import React from 'react';
import { UsernameSpan, WrapperCard } from 'components/layouts/cards/SearchedUserCard/styles';
import { Sizes } from 'types/interfaces';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import {
    avatarDiameter,
    defaultCardWidth,
    verifiedIconDiameter
} from 'components/layouts/cards/SearchedUserCard/constants';
import defaultAvatar from 'assets/defaults/default_avatar.svg';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { userUrl } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { VerifiedIcon } from 'components/common/imageComponents/VerifiedIcon';

export interface SearchedUserCardProps
    extends Sizes,
        Pick<YEAY.GetUserProfileResponse, 'isTrusted' | 'username' | 'profileImageUrl' | 'id'> {}

export const SearchedUserCard = ({
    isTrusted,
    id,
    profileImageUrl,
    username = '',
    width = defaultCardWidth,
    height
}: SearchedUserCardProps) => {
    const history = useHistory();
    const onClick = () => history.push(userUrl + id);

    return (
        <WrapperCard height={height} width={width} onClick={onClick}>
            <Section alignCenter>
                <Row marginRight="16px">
                    <AvatarImg alt="avatar" diameter={avatarDiameter} src={profileImageUrl || defaultAvatar} />
                </Row>
                <Row marginRight="5px">
                    <UsernameSpan>@{username.replace(/^@/, '')}</UsernameSpan>
                </Row>
                {isTrusted && <VerifiedIcon diameter={verifiedIconDiameter} />}
            </Section>
        </WrapperCard>
    );
};
