import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { avatarDiameter, cardWidth } from 'components/layouts/cards/TrendingCreatorCard/constants';
import { UsernameText } from 'components/layouts/cards/TrendingCreatorCard/styles';
import { secondaryPadding } from 'constants/styles';
import React from 'react';
import { ImgProperties, MarginRight, Name, NoopClick } from 'types';
import { AvatarLayout } from 'components/layouts/AvatarLayout';

interface Props
    extends Name,
        ImgProperties,
        NoopClick,
        MarginRight,
        Pick<YEAY.PlaylistSingleVideoResponse, 'isTrusted'> {}

export const TrendingCreatorCard = ({ name, src, marginRight, onClick, isTrusted }: Props) => (
    <Column marginBottom={secondaryPadding} marginRight={marginRight || secondaryPadding} width={cardWidth}>
        <Row alignCenter marginBottom="0" width={cardWidth} zIndex="2">
            <Row marginBottom="8px">
                <AvatarLayout isTrusted={isTrusted}>
                    <AvatarImg pointer diameter={avatarDiameter} src={src} onClick={onClick} />
                </AvatarLayout>
            </Row>
            <UsernameText>{name}</UsernameText>
        </Row>
    </Column>
);
