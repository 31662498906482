import { androidAppStoreLink, iosAppStoreLink } from 'constants/routes';
import { isAndroid, isIOS } from 'react-device-detect';
import { handleClickAppStoreApp, handleClickGooglePlayApp } from 'utils/googleTagManagerEvents';

export const headerHeight = '73px';
export const logoHeight = '38px';

export const usernameWrapperHeight = '30px';
export const usernameWrapperBorderRadius = `calc(${usernameWrapperHeight} / 2)`;

export const usernameTextFontSize = '12px';

export const getLinkApp = () => {
    if (isAndroid) return androidAppStoreLink;
    if (isIOS) return iosAppStoreLink;

    return '/';
};

export const setGTMEvents = () => {
    if (isAndroid) return handleClickGooglePlayApp;
    if (isIOS) return handleClickAppStoreApp;

    return () => {};
};
