import axios from './axios';

export const query = (data: YEAY.CreatePlaylistRequest) =>
    axios<YEAY.CreatePlaylistForVideosResponse>({
        url: '/playlist/query',
        data
    });

export const get = (data: YEAY.GetPlaylistVideoRequest) =>
    axios<YEAY.PlaylistSingleVideoResponse>({
        url: '/playlist/get',
        data
    });

export const queryByFilter = (data: YEAY.QueryPlaylistVideosRequest) =>
    axios<YEAY.QueryPlaylistVideosResponse>({
        url: '/playlist/query-videos',
        data
    });
