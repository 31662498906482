import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { PopupProps } from './index';

interface Props extends PopupProps {
    isMobile: boolean;
}

export const BackDrop = styled.div`
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    animation: fadeout 0.4s ease-out 1;
    animation-fill-mode: forwards;
    cursor: pointer;

    @keyframes fadein {
        0% {
            background-color: rgba(0, 0, 0, 0);
        }
        100% {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    @keyframes fadeout {
        0% {
            background-color: rgba(0, 0, 0, 0.2);
        }
        100% {
            background-color: rgba(0, 0, 0, 0);
        }
    }
`;

export const Content = styled.div<{ height: string }>`
    //position: absolute;
    //bottom: 0;
    //left: 0;
    width: 100%;
    height: ${({ height }) => height};
    //max-height: 80vh;
    overflow: auto;
    transform: translateY(100%);
    transition: transform 0.4s;
`;

export const PopupStyled = styled.div<Props>`
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    z-index: -1000;
    opacity: 0;
    visibility: hidden;
    transition: z-index 0.4s, opacity 0.4s, visibility 0.4s;

    ${isMobile &&
    css`
        ${BackDrop} {
            display: block;
        }
    `}
`;

// ${p =>
//     p.isShow &&
//     css`
//         z-index: 1000;
//         opacity: 1;
//         visibility: visible;

//         ${Content} {
//             transform: translateY(0);
//         }

//         ${BackDrop} {
//             background-color: rgba(0, 0, 0, 0.2);
//             animation: fadein 0.4s ease-out;
//         }
//     `}
