export const mainFeedUrl = '/index';
export const profileUrl = '/profile';
export const searchUrl = '/search';
export const videoIdUrl = '/:id';
export const homeUrl = '/';
export const referralUrl = '/refer/:code';

const videoIdParam = 'videoId=';
const userIdParam = 'userId=';
const hashtagParam = 'hashtag=';

export const videoUrl = mainFeedUrl + '?' + videoIdParam;
export const userUrl = mainFeedUrl + '?' + userIdParam;
export const hashtagUrl = searchUrl + '?' + hashtagParam;

export const yeayappDeepLink = 'yeayapp://player.yeay.com';
export const iosAppStoreLink = 'https://apps.apple.com/us/app/yeay/id1070699048';
export const androidAppStoreLink = 'https://play.google.com/store/apps/details?id=yeay.tv.yeay&hl=ru';
