import styled from 'styled-components';
import { scrollBarMobileMixin } from 'constants/styles';
import { Background, Sizes } from 'types';

export interface WrapperLineProps extends Sizes, Background {}

export const WrapperLine = styled.div<WrapperLineProps>`
    display: flex;
    overflow-y: scroll;
    padding-top: 17px;
    background-color: ${({ background }) => background || 'none'};
    height: ${({ height }) => height};
    scroll-behavior: smooth;
    ${scrollBarMobileMixin};
`;
