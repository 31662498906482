import {
    wrapperBorderRadius,
    wrapperBorderWidth,
    wrapperHeight,
    wrapperMargin,
    wrapperPadding,
    wrapperWidth
} from 'components/layouts/cards/TrendingVideoCard/constants';
import { ellipsisMixin, flexStart, grey5 } from 'constants/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const WrapperLink = styled(Link)`
    position: relative;
    ${flexStart};
    justify-content: flex-end;
    flex-direction: column;
    width: ${wrapperWidth};
    height: ${wrapperHeight};
    border-radius: ${wrapperBorderRadius};
    border: ${wrapperBorderWidth} solid ${grey5};
    margin-right: ${wrapperMargin};
    overflow: hidden;

    &:nth-child(2n) {
        margin-right: 0;
    }

    margin-bottom: ${wrapperMargin};
    padding: ${wrapperPadding};
`;

export const UsernameText = styled.span`
    ${ellipsisMixin};
    font-size: 12px;
    font-style: normal;
    width: 80px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
`;

export const RelativeAvatarBlock = styled.div`
    position: relative;
`;
