import { Column } from 'components/grid/wrappers/FlexWrapper';
import { fonts } from 'constants/styles/fonts';
import { disableDefaultButtonStyleMixin, flexCenter, scrollBarDesktopMixin } from 'constants/styles/mixins';
import { tablet } from 'constants/styles/sizes';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const darkGrey = '#f1f1fa';
const lightGrey = '#fbfaff';
const black = '#27272e';

const mobileTextCenter = css`
    @media (max-width: ${tablet}) {
        text-align: center;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 24px 32px;
    background-color: ${darkGrey};
    overflow-y: auto;
    ${scrollBarDesktopMixin};

    @media (max-width: ${tablet}) {
        padding: 16px;
    }
`;

export const InnerWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    background-color: ${lightGrey};
    border-radius: 8px;
`;

export const UserInfoWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding: 18px;
    border-bottom: 1px solid ${darkGrey};
`;

export const Title = styled.h2`
    font-family: ${fonts.poppins};
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: ${black};
    margin: 0;
    ${mobileTextCenter};
`;

export const Text = styled.p`
    font-family: ${fonts.sfUi};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${black};
    margin: 0;
    ${mobileTextCenter};
`;

export const VideosWrapper = styled.div`
    width: 100%;
    padding: 16px;

    @media (max-width: ${tablet}) {
        padding: 8px;
    }
`;

export const VideosGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;

    @media (max-width: ${tablet}) {
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
    }
`;

export const VideoCard = styled(Link)`
    width: 100%;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
`;

interface BackgroundProps {
    src?: string;
}

export const BackgroundWrapper = styled.div<BackgroundProps>`
    width: 100%;
    height: 100%;
    background: ${({ src }) => (src ? `url(${src})` : darkGrey)};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const InviteWrapper = styled.div`
    width: 100%;
    max-width: 364px;
    position: fixed;
    bottom: 16px;
    right: 24px;
    background-color: ${lightGrey};
    border-radius: 12px;
    box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.32);
    overflow: hidden;
    z-index: 10;
`;

export const CloseButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 24px;
    height: 24px;
    ${flexCenter};
`;
