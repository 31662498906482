import React from 'react';
import { Image } from 'components/common/imageComponents/Image';
import arrowImg from 'assets/img/bottom_arrow.svg';
import { Up, UpProps } from 'components/common/buttons/UpButton/styles';
import { NoopClick } from 'types';
import { arrowImgDiameter } from 'components/modals/MobileProductModal/constants';

interface UpButton extends UpProps, NoopClick {}

export const UpButton = ({ display, onClick }: UpButton) => (
    <Up display={display}>
        <Image
            pointer
            height={arrowImgDiameter}
            rotate={180}
            src={arrowImg}
            width={arrowImgDiameter}
            onClick={onClick}
        />
    </Up>
);
