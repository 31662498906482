import React from 'react';
import { Image } from 'components/common/imageComponents/Image';
import scrollButton from 'assets/img/scrollButton.svg';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Row } from 'components/grid/wrappers/FlexWrapper';
import { trendingsEffects } from 'stores/trendings';
import { TypeScrolledLine } from 'types';

const { setForwardScroll } = trendingsEffects;

interface Props extends TypeScrolledLine {}

export const ScrollButtons = ({ typeScrolledLine }: Props) => {
    const nextButtonClick = () => setForwardScroll({ type: 'forward', position: 0, parent: typeScrolledLine });
    const previousButtonClick = () => setForwardScroll({ type: 'back', position: 0, parent: typeScrolledLine });

    return (
        <Row>
            <MarginWrapper marginRight="10px">
                <Image src={scrollButton} onClick={previousButtonClick} />
            </MarginWrapper>
            <Image rotate={180} src={scrollButton} onClick={nextButtonClick} />
        </Row>
    );
};
