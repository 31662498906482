import { Image } from 'components/common/imageComponents/Image';
import { Row } from 'components/grid/wrappers/FlexWrapper';
import {
    arrowImgDiameter,
    nicknameWrapperMarginTop,
    trendingVideosHorizontalMargin
} from 'components/modals/MobileProductModal/constants';
import { Wrapper, WrapperPadding } from 'components/modals/MobileProductModal/styles';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mobileProductModalEvents, mobileProductModalStores } from 'stores/modal';
import arrowImg from 'assets/img/bottom_arrow.svg';
import { AbsoluteModalWrapper } from './styles';
import { commonStores } from 'stores/common';
import { withAdaptiveComponent } from 'hocs/withAdaptiveComponent';
import { TrendingHashtags } from 'components/common/blocks/TrendingHashtags';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { TrendingCreators } from 'components/common/blocks/TrendingCreators';
import { TopCreatorBlock } from 'components/common/blocks/TopCreatorsBlock';
import { trendingsEffects } from 'stores/trendings';
import { defaultScrollState } from 'constants/defaults';
import { UpButton } from 'components/common/buttons/UpButton';
import { CardProduct } from 'components/layouts/cards/CardProduct';
import { TrendingVideos } from 'components/common/videoComponents/TrendingVideos';
import { UserCard } from 'components/layouts/cards/UserCard';

export const MobileProductModalComponent = () => {
    const { visible, imageUrl, description = 'No description', name = 'No name', ...linksProps } = useStore(
        mobileProductModalStores.modal
    );
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [, height] = useStore(commonStores.sizesAdaptive);
    const [showUpButton, setShowUpButton] = useState(false);
    const onClose = () => {
        mobileProductModalEvents.closeModal();
        trendingsEffects.setForwardScroll(defaultScrollState);
    };

    const scrollListener = useCallback(() => {
        if (!wrapperRef.current) return;
        wrapperRef.current.scrollTop > 300 ? setShowUpButton(true) : setShowUpButton(false);
    }, []);

    const scrollToTop = () => {
        if (!wrapperRef.current) return;
        wrapperRef.current.scrollTop = 0;
    };

    useEffect(() => {
        if (!wrapperRef.current) return;
        visible
            ? wrapperRef.current.addEventListener('scroll', scrollListener)
            : wrapperRef.current.removeEventListener('scroll', scrollListener);
    }, [scrollListener, visible]);

    return (
        <>
            {visible && (
                <>
                    <UpButton display={showUpButton ? 'flex' : 'none'} onClick={scrollToTop} />
                    <Wrapper ref={wrapperRef} height={height}>
                        <WrapperPadding>
                            <AbsoluteModalWrapper height={height}>
                                <Image
                                    pointer
                                    height={arrowImgDiameter}
                                    src={arrowImg}
                                    width={arrowImgDiameter}
                                    onClick={onClose}
                                />
                            </AbsoluteModalWrapper>
                            <Row marginLeft="43px" marginTop={nicknameWrapperMarginTop}>
                                <UserCard />
                            </Row>
                            <CardProduct description={description} imageUrl={imageUrl} name={name} {...linksProps} />
                            <MarginWrapper marginTop="40px">
                                <TopCreatorBlock />
                            </MarginWrapper>
                        </WrapperPadding>
                        <MarginWrapper marginTop="40px">
                            <TrendingHashtags />
                        </MarginWrapper>
                        <WrapperPadding>
                            <MarginWrapper marginTop="44px">
                                <TrendingCreators />
                            </MarginWrapper>
                            <MarginWrapper
                                margin={`20px ${trendingVideosHorizontalMargin} 0 ${trendingVideosHorizontalMargin}`}
                            >
                                <TrendingVideos />
                            </MarginWrapper>
                        </WrapperPadding>
                    </Wrapper>
                </>
            )}
        </>
    );
};

export const MobileProductModal = withAdaptiveComponent(MobileProductModalComponent);
