import { ParsedLinkButtonProps } from 'components/common/buttons/ParsedLinkButton';
import { createEvent, createStore } from 'effector';

const openModal = createEvent<ModalData>();
const closeModal = createEvent();

export interface ModalData extends ParsedLinkButtonProps {
    imageUrl?: string;
    name?: string;
    description?: string;
}

interface ProductMobileModal extends ModalData {
    visible: boolean;
}

const modal = createStore<ProductMobileModal>({
    visible: false
})
    .on(openModal, (_, data) => ({
        visible: true,
        ...data
    }))
    .on(closeModal, () => ({
        visible: false
    }));

const mobileProductModalEvents = { openModal, closeModal };
const mobileProductModalEffects = {};
const mobileProductModalStores = { modal };

export { mobileProductModalEvents, mobileProductModalEffects, mobileProductModalStores };
