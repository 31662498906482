import { CSSProperties } from 'react';

const lineHeight = 2;

export const handleStyles: CSSProperties = {
    height: 14,
    width: 14,
    backgroundColor: '#FBFAFF',
    marginTop: -6,
    marginLeft: 0,
    border: 'none',
    boxShadow: 'none',
    opacity: 1
};

export const trackStyles: CSSProperties = {
    height: lineHeight,
    backgroundColor: '#00FF44',
    borderRadius: 4,
    cursor: 'pointer'
};

export const railStyles: CSSProperties = {
    width: '100%',
    height: lineHeight,
    backgroundColor: '#C4C4CD',
    borderRadius: 4,
    cursor: 'pointer'
};
