import React, { Component, FC } from 'react';
import { commonEvents } from 'stores/common';

const scale = 1.77777;

interface HocState {
    isShowProducts: boolean;
    isRelatedVideo: boolean;
    width: number;
    height: number;
}

export function withAdaptiveComponent(WrappedComponent: FC<HocState>, isWide = false) {
    return class extends Component {
        state = {
            isShowProducts: true,
            isRelatedVideo: true,
            width: 0,
            height: 0
        };

        updateSize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            // Magic number is indent header and paddings
            //const indentHeader = isMobile ? 0 : 138;
            const widthWindow = window.innerWidth;
            const heightWindow = window.innerHeight;
            const width = heightWindow / scale;
            const height = width > widthWindow ? widthWindow * scale : heightWindow;

            commonEvents.setSizesAdaptive([width > widthWindow ? widthWindow : width, height]);
            commonEvents.setSizesWindowAdaptive([window.innerWidth, window.innerHeight]);
            this.setState({
                width: isWide ? widthWindow : width > widthWindow ? widthWindow : width,
                height,
                isShowProducts: widthWindow >= width * 3,
                isRelatedVideo: widthWindow >= width * 2
            });
        };

        componentDidMount() {
            //for bug with "swipe up" function in stories of Instagram - when user swipe up - browser in instagram set high height and UI show ugly
            setTimeout(() => {
                this.updateSize();
            }, 0);

            window.addEventListener('resize', this.updateSize);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateSize);
        }

        render() {
            return <WrappedComponent {...this.props} {...this.state} />;
        }
    };
}
