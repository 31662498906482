import { Popup } from 'components/common/Popup';
import { Content as ContentPopup } from 'components/common/Popup/styles';
import { fonts, quaternaryPadding, scrollBarDesktopMixin, secondaryPadding, tertiaryPadding } from 'constants/styles';
import styled, { css } from 'styled-components';

interface PopupProps {
    staticState?: boolean;
}

export const Title = styled.h3`
    margin: 0;
    margin-bottom: 15px;
    font-family: ${fonts.sfuidSemibold};
    font-size: 11px;
`;

export const TrendingTitle = styled.h3`
    font-family: ${fonts.sfuidSemibold};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: ${secondaryPadding};
`;

export const TrendingUserName = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
`;

interface IContent {
    isMobile: boolean;
}

export const Content = styled.div<IContent>`
    padding: 0 ${tertiaryPadding} 0 ${quaternaryPadding};
    width: 100%;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    ${scrollBarDesktopMixin};

    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    ${p =>
        p.isMobile &&
        css`
            padding-top: 30px;
        `}
`;

export const PopupStyled = styled(Popup)<PopupProps>`
    ${p =>
        p.staticState &&
        css`
            position: relative;
            z-index: 1;
            opacity: 1;
            visibility: visible;
            height: 100%;

            ${ContentPopup} {
                position: relative;
                height: 100%;
                transform: translateY(0);
            }
        `}

    @media (orientation: landscape) {
        width: 100%;
    }
`;

export const TrendingWrapper = styled.div``;
export const ModalTrendingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
`;
