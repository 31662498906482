import { createEvent, createStore } from 'effector';
import { wait } from 'utils/common';
import { v4 as uuid4 } from 'uuid';

interface Notification {
    message: string;
    description: string;
    id: string;
}

// remove by id
const removeNotification = createEvent<string>();
const setNotificationEvent = createEvent<Notification>();

const notifications = createStore<Notification[]>([])
    .on(setNotificationEvent, (state, notification) => [...state, notification])
    .on(removeNotification, (state, notificationId) => state.filter(({ id }) => id !== notificationId));

const setNotification = (notification: Omit<Notification, 'id'>) => {
    const id = uuid4();

    setNotificationEvent({
        ...notification,
        id
    });

    wait().then(() => removeNotification(id));
};

export const notificationsStores = { notifications };
export const notificationsEffects = { setNotification, removeNotification };
