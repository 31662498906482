import { isAndroid, isIOS } from 'react-device-detect';

export const usernameWrapperHeight = '30px';
export const usernameWrapperBorderRadius = `calc(${usernameWrapperHeight} / 2)`;

export const usernameTextFontSize = '12px';

export const getLinkApp = () => {
    if (isAndroid) return 'https://play.google.com/store/apps/details?id=yeay.tv.yeay&hl=ru';
    if (isIOS) return 'https://apps.apple.com/us/app/yeay/id1070699048';

    return '/';
};
