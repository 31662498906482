import React, { FC, useEffect, useRef } from 'react';
import { WrapperLine, WrapperLineProps } from './styles';
import { trendingsStores } from 'stores/trendings';
import { useStore } from 'effector-react';
import { TypeScrolledLine } from 'types';
import { scrollShift } from 'components/common/blocks/ScrolledBlock/constants';

interface Props extends WrapperLineProps, TypeScrolledLine {}

export const ScrolledBlock: FC<Props> = ({ children, typeScrolledLine, ...props }) => {
    const scrolledBlockRef = useRef<HTMLDivElement>(null);
    // const scrolledCreatorsLineRef = useRef<HTMLDivElement>(null);
    const forwardScroll = useStore(trendingsStores.forwardScroll);

    useEffect(() => {
        if (!scrolledBlockRef.current) return;

        const scrolledBlock = scrolledBlockRef.current;

        if (typeScrolledLine === forwardScroll.parent) {
            scrolledBlock.scrollLeft =
                scrolledBlock.scrollLeft + scrollShift * (forwardScroll.type === 'forward' ? 1 : -1);
        }
    }, [forwardScroll.type, forwardScroll, typeScrolledLine]);

    // useEffect(() => {
    //     if (!scrolledCreatorsLineRef.current) return;
    //
    //     if (typeScrolledLine === forwardScroll.parent) {
    //         scrolledCreatorsLineRef.current.scrollLeft =
    //             forwardScroll.type === 'forward'
    //                 ? scrolledCreatorsLineRef.current.scrollLeft + scrollShift
    //                 : scrolledCreatorsLineRef.current.scrollLeft - scrollShift;
    //     }
    // }, [forwardScroll.type, forwardScroll, typeScrolledLine]);

    // useEffect(() => {
    //     if (!scrolledBlockRef.current) return;
    //     if (visible) {
    //         scrolledBlockRef.current.scrollLeft = 0;
    //     }
    // }, [visible]);

    // const [windowAdaptiveWidth] = useStore(commonStores.sizesWindowAdaptive);

    //
    // const scrollListener = useCallback(() => {
    //     if (!scrolledBlockRef.current) return;
    //     const percentageScroll =
    //         scrolledBlockRef.current.scrollLeft /
    //         (scrolledBlockRef.current.scrollWidth - windowAdaptiveWidth + 2 * parseInt(primaryPadding));
    //     const roundedPercentageScroll = Math.round(percentageScroll * 100);
    //
    //     // console.log(
    //     //     roundedPercentageScroll,
    //     //     (roundedPercentageScroll - previousPercentageScroll) % 10,
    //     //     (roundedPercentageScroll - previousPercentageScroll) % 10 === 9
    //     // );
    //     if (roundedPercentageScroll % 10 === 0) {
    //         console.log(roundedPercentageScroll);
    //         typeScrolledLine === 'hashtag'
    //             ? trendingsEffects.setHashtagsPercentageScroll(percentageScroll)
    //             : trendingsEffects.setCreatorsPercentageScroll(percentageScroll);
    //     }
    //
    //     // if (String(roundedPercentageScroll - previousPercentageScroll) === '10') {
    //     //     setPreviousPercentageScroll(state => state + 10);
    //     //     // console.log('test', percentageScroll);
    //     //     typeScrolledLine === 'hashtag'
    //     //         ? trendingsEffects.setHashtagsPercentageScroll(percentageScroll)
    //     //         : trendingsEffects.setCreatorsPercentageScroll(percentageScroll);
    //     // }
    // }, [typeScrolledLine, windowAdaptiveWidth]);
    //
    // useEffect(() => {
    //     if (!scrolledBlockRef.current) return;
    //     visible
    //         ? scrolledBlockRef.current.addEventListener('scroll', scrollListener)
    //         : scrolledBlockRef.current.removeEventListener('scroll', scrollListener);
    // }, [scrollListener, visible]);
    //
    // useEffect(() => {
    //     if (visible) {
    //         trendingsEffects.setHashtagsPercentageScroll(0);
    //         trendingsEffects.setCreatorsPercentageScroll(0);
    //     }
    // }, [visible]);

    // if (typeScrolledLine === 'hashtag') {
    return (
        <WrapperLine ref={scrolledBlockRef} {...props}>
            {children}
        </WrapperLine>
    );
    // } else {
    //     return (
    //         <WrapperLine ref={scrolledCreatorsLineRef} {...props}>
    //             {children}
    //         </WrapperLine>
    //     );
    // }
};
