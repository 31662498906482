import { MobileProductModal } from 'components/modals/MobileProductModal';
import { homeUrl, mainFeedUrl, profileUrl, referralUrl, searchUrl, videoIdUrl } from 'constants/routes';
import { GlobalStyle } from 'constants/styles';
import { useStore } from 'effector-react';
import { Home } from 'pages/Home';
import { LoadingPage } from 'pages/LoadingPage';
import { Main } from 'pages/Main';
import { Referral } from 'pages/Referral';
import React, { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { IdleTimer } from 'utils/IdleTimer';
import { Notifications } from './components/common/Notifications';
import { userEffects, userStores } from './stores/user';
import './styles/fonts.css';

const App = () => {
    const { token } = useStore(userStores.user);

    useEffect(() => {
        !token && userEffects.loadToken(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!token) return <LoadingPage />;

    if (isIOS && !isMobile) return null;

    return (
        <>
            <IdleTimer />
            <GlobalStyle />
            <Notifications />
            <Router>
                <MobileProductModal />
                <Switch>
                    <Route exact component={Main} path={mainFeedUrl} />
                    <Route exact component={Main} path={profileUrl} />
                    <Route exact component={Home} path={searchUrl} />
                    <Route exact component={Referral} path={referralUrl} />
                    <Route component={Main} path={videoIdUrl} />
                    <Route exact component={Home} path={homeUrl} />
                </Switch>
            </Router>
        </>
    );
};

export default App;
