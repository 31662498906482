import Stub from 'assets/defaults/default_product.png';
import { ParsedLinkButton } from 'components/common/buttons/ParsedLinkButton';
import { Image } from 'components/common/imageComponents/Image';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { productImgDiameter } from 'components/layouts/cards/CardProduct/constants';
import { primaryPadding, secondaryPadding } from 'constants/styles';
import React from 'react';
import { Description, ProductWrapper, ProductTitleText, ProductNameText, ProductDescriptionText } from './styles';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { isMobile } from 'react-device-detect';
import {
    descriptionWidth,
    imgMarginRight,
    productImgBorderRadius
} from 'components/modals/MobileProductModal/constants';
import { ModalData } from 'stores/modal';

interface Props extends ModalData, Pick<YEAY.AffiliateProductResponse, 'hasAffiliateLink'> {}

export const CardProduct = ({ imageUrl, name, description, hasAffiliateLink, ...rest }: Props) => {
    if (isMobile) {
        return (
            <ProductWrapper>
                <Section marginBottom={primaryPadding}>
                    <ProductTitleText>In This Video</ProductTitleText>
                </Section>
                <Section marginBottom="0">
                    <Column marginRight={imgMarginRight}>
                        <Image
                            borderRadius={productImgBorderRadius}
                            height={productImgDiameter}
                            src={imageUrl || ''}
                            width={productImgDiameter}
                        />
                    </Column>
                    <Column width={descriptionWidth}>
                        <ProductNameText>{name}</ProductNameText>
                        <ProductDescriptionText>{description}</ProductDescriptionText>
                        <ParsedLinkButton {...rest} />
                    </Column>
                </Section>
            </ProductWrapper>
        );
    }
    return (
        <Column noWrap flexShrink={0} width="100%">
            <Section marginBottom="20px">
                <Row noWrap>
                    <MarginWrapper marginRight={secondaryPadding}>
                        <Image
                            contain
                            borderRadius="10px"
                            height={productImgDiameter}
                            src={imageUrl || Stub}
                            width={productImgDiameter}
                        />
                    </MarginWrapper>
                    {description && <Description>{description}</Description>}
                </Row>
            </Section>
            {hasAffiliateLink && (
                <Section height="48px">
                    <ParsedLinkButton {...rest} />
                </Section>
            )}
        </Column>
    );
};
