import styled from 'styled-components';
import { colors } from 'constants/styles';
import { upButtonDiameter } from './constants';

export interface UpProps {
    display: 'flex' | 'none';
}

export const Up = styled.div<UpProps>`
    position: absolute;
    display: ${({ display }) => display};
    justify-content: center;
    align-items: center;
    right: 20px;
    bottom: 20px;
    z-index: 100;
    background-color: ${colors.green};
    border-radius: 50%;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    height: ${upButtonDiameter};
    width: ${upButtonDiameter};
`;
