import defaultImg from 'assets/defaults/default_img.svg';
import playImg from 'assets/img/play.svg';
import { Image } from 'components/common/imageComponents/Image';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { playImgDiameter } from 'components/layouts/cards/TrendingVideoCard/constants';
import {
    AbsoluteImg,
    FeaturesWrapper,
    ViewsText,
    WrapperLink
} from 'components/layouts/cards/TrendingVideoCard/styles';
import React from 'react';
import { NoopClick } from 'types';
import { numberConverter } from 'utils/parsers';
import { videoUrl } from 'constants/routes';

interface Props extends YEAY.GetTrendVideosResponse, YEAY.PlaylistSingleVideoResponse, NoopClick {}

export const TrendingVideoCard = ({ id = '', viewCount, thumbnailUrl, onClick }: Props) => (
    <WrapperLink to={videoUrl + id} onClick={onClick}>
        <AbsoluteImg src={thumbnailUrl || defaultImg} />
        <Row alignCenter marginBottom="0" zIndex="2">
            {/* <Column marginRight="8px">
                <AvatarImg hasBorder diameter="24px" src={profileImageUrl} />
            </Column> */}
            <FeaturesWrapper>
                <Column marginRight="4px">
                    <Image height={playImgDiameter} src={playImg} width={playImgDiameter} />
                </Column>
                <ViewsText>{numberConverter(viewCount || 0)}</ViewsText>
            </FeaturesWrapper>
        </Row>
    </WrapperLink>
);
