import { Header } from 'components/common/Header';
import { NewHeader } from 'components/common/NewHeader';
import { MainStyled } from 'components/layouts/MainLayout/styles';
import { appVersion, environment } from 'constants/global';
import React, { FC, useEffect } from 'react';

interface Props {
    isNewHeader?: boolean;
}

export const MainLayout: FC<Props> = ({ children, isNewHeader = false }) => {
    useEffect(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    return (
        <MainStyled className={`YEWP ${appVersion} ${environment}`}>
            {isNewHeader ? <NewHeader /> : <Header />}
            {children}
        </MainStyled>
    );
};
