import {
    removeImgDiameter,
    removeImgMarginRight,
    wrapperBorderRadius,
    wrapperHeight,
    wrapperHorizontalPadding,
    wrapperMargin
} from 'components/common/Hashtag/constants';
import { hashtagFamily, hashtagFontSize } from 'components/common/ModalProducts/constants';
import { grey3, scrollBarDesktopMixin } from 'constants/styles';
import styled from 'styled-components';
import { Background, MarginBottom, Pointer } from 'types';

export interface WrapperProps extends Background, Pointer, MarginBottom {
    fontWeight?: number;
    removeIcon?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    background: ${grey3};
    border-radius: ${wrapperBorderRadius};
    ${({ background }) => (background ? `background: ${background};` : ``)};
    font-family: ${hashtagFamily};
    font-size: ${hashtagFontSize};
    font-style: normal;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : `400`)};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    line-height: ${wrapperHeight};
    letter-spacing: 0px;
    height: ${wrapperHeight};
    text-align: left;
    white-space: nowrap;
    margin-right: ${wrapperMargin};
    padding: 0 ${wrapperHorizontalPadding};
    ${({ pointer }) => pointer && 'cursor: pointer;'};
`;

interface WrapperHashtagLineProps {
    flexWrap?: boolean;
}

export const WrapperHashtagLine = styled.div<WrapperHashtagLineProps>`
    display: flex;
    overflow: auto;
    ${({ flexWrap }) => flexWrap && 'flex-wrap: wrap;'}
    margin-bottom: ${wrapperMargin};
    ${scrollBarDesktopMixin};
`;

export const RemoveImg = styled.img`
    height: ${removeImgDiameter};
    width: ${removeImgDiameter};
    vertical-align: middle;
    margin-right: ${removeImgMarginRight};
    cursor: pointer;
`;
