import React, { MouseEventHandler } from 'react';
import { ImgProps } from './types';
import { LazyImage } from 'react-lazy-images';
import { CustomImage } from './styles';

export interface CustomImageProps extends ImgProps {
    alt?: string;
    src: string;
    onClick?: MouseEventHandler;
}

export const CustomImg = ({ alt, src, onClick, ...rest }: CustomImageProps) => (
    <LazyImage
        actual={({ imageProps }) => <CustomImage onClick={onClick} {...rest} {...imageProps} />}
        alt={alt}
        debounceDurationMs={200}
        placeholder={({ ref }) => <CustomImage ref={ref} alt={alt} onClick={onClick} {...rest} src={src} />}
        src={src}
    />
);
