import axios from './axios';

export const createAccountAnonymous = (data: YEAY.UserCreateAnonymousAccountRequest) =>
    axios<YEAY.UserJwtTokenResponse>(
        {
            url: '/user/create-account-anonymous',
            data
        },
        false
    );

export const getProfile = (data: YEAY.GetUserProfileRequest) =>
    axios<YEAY.GetUserProfileResponse>({
        url: '/user/get-profile',
        data
    });
