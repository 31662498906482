import { Row } from 'components/grid/wrappers/FlexWrapper';
import { fonts, scrollBarDesktopMixin } from 'constants/styles';
import { laptop, tablet } from 'constants/styles/sizes';
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #f1f1fa;
    width: 100%;
    max-width: 800px;
    height: 100%;
    overflow-y: auto;
    ${scrollBarDesktopMixin};

    @media (min-width: ${tablet}) {
        padding: 24px 32px;
    }

    @media (min-width: ${laptop}) {
        padding: 24px 40px;
    }
`;

export const Grid = styled.div`
    display: grid;

    @media (min-width: ${tablet}) {
        grid-template-columns: 384px auto;
        grid-gap: 24px;
    }
`;

export const UserCard = styled.div`
    background: #fbfaff;
    border-radius: 12px;
    width: 100%;
`;

export const Hashtag = styled.div`
    display: inline-block;
    background: #f1f1fa;
    font-family: ${fonts.sfuidSemibold};
    font-size: 14px;
    line-height: 20px;
    color: #27272e;
    border-radius: 16px;
    padding: 4px 8px;
    margin-bottom: 8px;
    margin-right: 8px;
`;

export const UserCardSection = styled(Row)`
    padding: 8px 16px;

    &:not(:last-child) {
        border-bottom: 1px solid #f1f1fa;
    }
`;

export const UserHashtagSection = styled(Row)`
    padding: 8px 16px 0 16px;
    border-bottom: 1px solid #f1f1fa;
`;

export const HashtagStub = styled.div`
    height: 39px;
`;

export const VisitButton = styled.a`
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    background-color: #00ff44;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27272e;
    font-family: ${fonts.sfuidSemibold};
    text-align: center;

    &:hover {
        background-color: #6bff70;
    }

    &:active {
        background-color: #00d300;
    }
`;

export const UserWrapper = styled(Row)`
    background: #f6f6ff;
    border-radius: 8px;
    padding-right: 8px;
    font-family: ${fonts.sfuidSemibold};
    font-size: 16px;
    line-height: 24px;
    color: #27272e;
`;

export interface AvatarProps {
    size?: string;
    src?: string;
}

export const StyledAvatar = styled.div<AvatarProps>`
    width: ${({ size }) => size || '32px'};
    min-width: ${({ size }) => size || '32px'};
    height: ${({ size }) => size || '32px'};
    min-height: ${({ size }) => size || '32px'};
    border-radius: 50%;
    ${({ src }) => src && `background-image: url("${src}")`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    z-index: 1;
`;
