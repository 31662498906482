import calculateSize from 'calculate-size';
import { wrapperHorizontalPadding, wrapperMargin } from 'components/common/Hashtag/constants';
import { fonts } from 'constants/styles';

export const hasPrefix = true;
export const hashtagFamily = fonts.sfuidSemibold;
export const hashtagFontSize = '12px';

export const hashtagsPaddingSize = parseInt(wrapperMargin) + 2 * parseInt(wrapperHorizontalPadding);

export const calculateHashtagWidth = (str: string, fontWeight = '400') =>
    calculateSize(str + (hasPrefix ? '#' : ''), {
        font: hashtagFamily,
        fontSize: hashtagFontSize,
        fontWeight: fontWeight
    }).width;

// export const array = [{ tag: 'awdadadawda' }, { tag: 'awdadadawda' }, { tag: 'awdadadawda' }, { tag: 'awdadadawda' }];
