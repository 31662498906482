import styled, { css } from 'styled-components';
import { colors } from 'constants/styles';
import { isMobile } from 'react-device-detect';

export const StubWrapper = styled.div`
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: none;
    background-color: rgba(0, 0, 0, 0.4);

    ${isMobile &&
    css`
      @media (orientation: landscape) {
        display: flex;
      
    `}
`;

export const StubContentWrapper = styled.div`
    text-align: center;
`;

export const StubImage = styled.img`
    margin-bottom: 12px;
    width: 70px;
    height: 70px;
`;

export const StubDescription = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    max-width: 164px;
    letter-spacing: 0.6px;
    color: ${colors.white};
`;
