import { primaryPadding } from 'constants/styles';
import { pixelsAddition } from 'utils/parsers';

//for hide background player
export const addedHeightToModalWrapper = '400px';
export const wrapperHeight = `calc(100vh + ${addedHeightToModalWrapper})`;
export const wrapperBorderRadius = '23px';
export const wrapperPadding = primaryPadding;
export const imgMarginRight = '10px';

export const trendingVideosHorizontalMargin = '10px';

export const productImgDiameter = '100px';
export const productImgBorderRadius = '10px';
export const descriptionWidth = `calc(100% - ${pixelsAddition(
    wrapperPadding,
    pixelsAddition(imgMarginRight, productImgDiameter)
)})`;
export const arrowImgDiameter = '18px';

export const nicknameWrapperMarginTop = '40px';
