import React from 'react';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { TrendingCreatorCard } from 'components/layouts/cards/TrendingCreatorCard';
import { marginRightTrendingCard } from 'components/layouts/cards/TrendingCreatorCard/constants';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { useStore } from 'effector-react';
import { trendingsStores } from 'stores/trendings';
import { BlockTitle } from 'components/common/typography/BlockTitle';
import { ScrolledBlock } from 'components/common/blocks/ScrolledBlock';
import { ScrollButtons } from 'components/common/ScrollButtons';
import { topCreatorsCount } from 'constants/stores';
import { isMobile } from 'react-device-detect';
import { TrendingTitle } from 'components/common/ModalProducts/styles';
import { useHistory } from 'react-router-dom';
import { userUrl } from 'constants/routes';

export const TrendingCreators = () => {
    const isAllTrendingCreatorsVisible = useStore(trendingsStores.isAllTrendingCreatorsVisible);
    const users = useStore(trendingsStores.users);
    const history = useHistory();
    // const trendingCreatorsScrollWidth = useStore(trendingsStores.trendingCreatorsScrollWidth);
    // const trendingCreatorsPercentageScroll = useStore(trendingsStores.trendingCreatorsPercentageScroll);
    if (isMobile) {
        return (
            <>
                {isAllTrendingCreatorsVisible ? (
                    <Row alignCenter justifyBetween>
                        <MarginWrapper marginBottom="8px">
                            <BlockTitle>Trending creators</BlockTitle>
                        </MarginWrapper>
                        <MarginWrapper marginRight="17px">
                            {/*<Image src={moreImg} />*/}
                            {/*<TrendingScroll*/}
                            {/*    percentageScroll={trendingCreatorsPercentageScroll}*/}
                            {/*    width={trendingCreatorsScrollWidth + 'px'}*/}
                            {/*/>*/}
                            <ScrollButtons typeScrolledLine="creators" />
                        </MarginWrapper>
                    </Row>
                ) : (
                    <MarginWrapper marginBottom="8px">
                        <BlockTitle>Trending creators</BlockTitle>
                    </MarginWrapper>
                )}
                <Section>
                    <ScrolledBlock height="122px" typeScrolledLine="creators">
                        {users.slice(topCreatorsCount).map(({ userId, username = '', profileImageUrl, isTrusted }) => (
                            <TrendingCreatorCard
                                key={userId}
                                isTrusted={isTrusted}
                                marginRight={marginRightTrendingCard}
                                name={username}
                                src={profileImageUrl}
                                onClick={() => history.push(userUrl + userId)}
                            />
                        ))}
                    </ScrolledBlock>
                </Section>
            </>
        );
    } else {
        return (
            <>
                <TrendingTitle>Trending creators</TrendingTitle>
                <Section>
                    {users.map(({ userId, username = '', profileImageUrl, isTrusted }) => (
                        <TrendingCreatorCard
                            key={userId}
                            isTrusted={isTrusted}
                            name={username}
                            src={profileImageUrl}
                            onClick={() => history.push(userUrl + userId)}
                        />
                    ))}
                </Section>
            </>
        );
    }
};
