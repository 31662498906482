import verifiedIcon from 'assets/img/verifiedIconNew.svg';
import { InviteContent } from 'components/common/InviteContent';
import { Img } from 'components/common/imageComponents/Image/styles';
import { Column, Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { userUrl } from 'constants/routes';
import { useStore } from 'effector-react';
import { Player } from 'pages/Main/VideoPage/Player';
import {
    Grid,
    Hashtag,
    HashtagStub,
    StyledAvatar,
    UserCard,
    UserCardSection,
    UserHashtagSection,
    UserWrapper,
    VisitButton,
    Wrapper
} from 'pages/Main/VideoPage/styles';
import React, { FC } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { baseURL } from 'services/axios';
import { userStores } from 'stores/user';

interface Props {
    video: YEAY.PlaylistSingleVideoResponse;
}

export const VideoPage: FC<Props> = ({ video }) => {
    const sessionId = useStore(userStores.sessionId);
    const visitLink = `${baseURL}out/${video.ownerId}/${video.id}/${video.primaryProductId}/${sessionId}`;

    return (
        <Wrapper>
            <Grid>
                <Player isPlaying video={video} visitLink={visitLink} />
                {!(isTablet || isMobile) && (
                    <Column width="100%">
                        <UserCard>
                            <UserCardSection>
                                <Link to={`${userUrl}${video.ownerId}`}>
                                    <UserWrapper alignCenter>
                                        <StyledAvatar src={video.profileImageUrl} />
                                        <Row marginLeft="12px">@{video.username}</Row>
                                        {video.isTrusted && (
                                            <Row marginLeft="8px">
                                                <Img height="22px" src={verifiedIcon} width="22px" />
                                            </Row>
                                        )}
                                    </UserWrapper>
                                </Link>
                            </UserCardSection>

                            <UserHashtagSection>
                                {video.hashTags && video.hashTags.length > 0 ? (
                                    video.hashTags.map(tag => <Hashtag key={tag}>#{tag}</Hashtag>)
                                ) : (
                                    <HashtagStub />
                                )}
                            </UserHashtagSection>
                            <UserCardSection>
                                <VisitButton href={visitLink} rel="noopener noreferrer" target="_blank">
                                    Visit Website
                                </VisitButton>
                            </UserCardSection>
                        </UserCard>
                        <Section marginTop="16px">
                            <InviteContent />
                        </Section>
                    </Column>
                )}
            </Grid>
        </Wrapper>
    );
};
