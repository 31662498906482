import { calculateHashtagWidth, hashtagsPaddingSize } from 'components/common/ModalProducts/constants';
import { quaternaryPadding } from 'constants/styles';

export const calculateHashtagsSliceIndex = (width: number, hashtags: YEAY.GetTrendingTagResponse[]) => {
    const widthBlock = width - 2 * parseInt(quaternaryPadding);
    let hashtagsWidth = 0;
    let index = 0;
    for (let i = 0; i < hashtags.length; i++) {
        hashtagsWidth += calculateHashtagWidth(hashtags[i]?.tag || '') + hashtagsPaddingSize;
        if (hashtagsWidth >= widthBlock) {
            index = i;
            break;
        }
    }

    return index;
};
