import arrowImg from 'assets/img/right_arrow.svg';
import { Button } from 'components/common/buttons/Button';
import { ButtonText, StyledRow } from 'components/common/buttons/ParsedLinkButton/styles';
import { Image } from 'components/common/imageComponents/Image';
import { useStore } from 'effector-react';
import React from 'react';
import { baseURL } from 'services/axios';
import { userStores } from 'stores/user';

export interface ParsedLinkButtonProps {
    productId?: string;
    videoId?: string;
    userId?: string;
}

export const ParsedLinkButton = ({ productId = '', videoId = '', userId = '' }: ParsedLinkButtonProps) => {
    const sessionId = useStore(userStores.sessionId);

    return (
        <Button
            bgColor="green"
            height="48px"
            href={`${baseURL}out/${userId}/${videoId}/${productId}/${sessionId}`}
            width="100%"
        >
            <StyledRow>
                <ButtonText>View on website</ButtonText> <Image height="15px" src={arrowImg} width="16px" />
            </StyledRow>
        </Button>
    );
};
