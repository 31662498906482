import { defaultImgDiameter } from 'components/common/imageComponents/AvatarImg/constants';
import { AvatarImgStyledProps } from 'components/common/imageComponents/AvatarImg/types';
import { white } from 'constants/styles';
import styled from 'styled-components';

export const StyledImg = styled.img<AvatarImgStyledProps>`
    width: ${({ diameter }) => diameter || defaultImgDiameter};
    height: ${({ diameter }) => diameter || defaultImgDiameter};
    border-radius: 50%;
    ${({ hasBorder }) => (hasBorder ? `2px solid ${white}` : ``)};
    object-fit: cover;
    ${({ pointer }) => pointer && 'cursor: pointer;'};
`;
