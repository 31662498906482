import { multiplyPixels } from 'utils/parsers';

// primaries
export const primaryPadding = '10px';
export const secondaryPadding = multiplyPixels(primaryPadding, 2);
export const tertiaryPadding = multiplyPixels(primaryPadding, 3);
export const quaternaryPadding = multiplyPixels(primaryPadding, 4);

// heights
export const headerLogoHeight = '44px';

//adaptive
export const xs = '374px';

// new adaptive
export const small = '320px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopLarge = '1280px';
export const desktop = '1440px';
export const large = '1600px';
