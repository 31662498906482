import { Column } from 'components/grid/wrappers/FlexWrapper/styles';
import { fonts } from 'constants/styles/fonts';
import styled from 'styled-components';

export const InviteContentWrapper = styled(Column)`
    padding: 28px 10px 24px;
    border-radius: 12px;
    background-color: #fbfaff;
`;

export const Title = styled.h3`
    font-family: ${fonts.poppins};
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #27272e;
    margin-top: 0;
    margin-bottom: 0;
`;

export const Subtitle = styled.span`
    font-family: ${fonts.sfUi};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #27272e;
    text-align: center;
`;

export const Link = styled.a`
    width: 138px;
    height: 50px;
    border-radius: 30px;
    padding: 12px 24px;
    background-color: #f1f1fa;
    display: flex;
    align-items: center;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
`;
