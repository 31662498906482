import styled, { css } from 'styled-components';
import { wrapperBorderRadius } from 'components/layouts/cards/LatestVideoCard/constants';
import { Sizes } from 'types';
import { isMobile } from 'react-device-detect';

export const WrapperCard = styled.div<Sizes>`
    position: relative;
    width: ${({ width }) => width || '270px'};
    height: ${({ height }) => height || '460px'};
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: ${wrapperBorderRadius};
    margin-top: 40px;
    cursor: pointer;
    ${isMobile &&
    css`
        margin-top: 0;
        margin-bottom: 8px;
    `}
`;

export const AbsoluteImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${wrapperBorderRadius};
    object-fit: cover;
    z-index: 1;
`;

export const FeaturesWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 1;
    ${isMobile &&
    css`
        bottom: 8px;
        left: 8px;
    `}
`;

interface AvatarImgProps {
    url: string;
}
export const AvatarImg = styled.img<AvatarImgProps>`
    width: 35px;
    height: 35px;
    border: 2px solid #ffffff;
    border-radius: 30px;
    //object-fit: cover;
    background-image: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;

    ${isMobile &&
    css`
        width: 17px;
        height: 17px;
    `}
`;

export const Views = styled.div`
    height: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    mix-blend-mode: normal;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 8px;
    ${isMobile &&
    css`
        padding: 0 5px;
        height: 18px;
    `}
`;

export const ViewCount = styled.span`
    color: white;
    margin-left: 5px;
    height: 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
`;
