import loaderImg from 'assets/img/load.gif';
import { Image } from 'components/common/imageComponents/Image';
import { loaderHeight, loaderWidth } from 'components/common/Loader/constants';
import { MobileWrapper } from 'components/common/Loader/styles';
import React from 'react';

export const DesktopLoader = () => <Image height={loaderHeight} src={loaderImg} width={loaderWidth} />;
export const MobileLoader = () => (
    <MobileWrapper>
        <DesktopLoader />
    </MobileWrapper>
);
