import ImgAppStore from 'assets/img/app-store-img.png';
import ImgGooglePlay from 'assets/img/google-play-img.png';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper/styles';
import { androidAppStoreLink, iosAppStoreLink } from 'constants/routes';
import React from 'react';
import { Image, InviteContentWrapper, Link, Subtitle, Title } from './styles';

export const InviteContent = () => (
    <InviteContentWrapper>
        <Section justifyCenter marginBottom="12px">
            <Title>
                <span aria-label="" role="img">
                    👋
                </span>{' '}
                New to YEAY?
            </Title>
        </Section>

        <Section marginBottom="28px">
            <Subtitle>Discover brand collaborations, submit your video and earn rewards!</Subtitle>
        </Section>

        <Section justifyCenter noWrap>
            <Row marginRight="8px">
                <Link href={iosAppStoreLink} rel="noopener noreferrer" target="_blank">
                    <Image src={ImgAppStore} />
                </Link>
            </Row>
            <Row marginLeft="8px">
                <Link href={androidAppStoreLink} rel="noopener noreferrer" target="_blank">
                    <Image src={ImgGooglePlay} />
                </Link>
            </Row>
        </Section>
    </InviteContentWrapper>
);
