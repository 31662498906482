import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const MobileWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    ${flexCenter};
    z-index: 10;
`;
