export const getPlayerTime = (timestamp?: number) => {
    if (!timestamp) return '00:00';

    const time = Math.ceil(timestamp);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) - hours * 60;
    const seconds = time % 60;

    const result =
        hours > 0
            ? [hours.toString(), minutes.toString(), seconds.toString().padStart(2, '0')]
            : [minutes.toString(), seconds.toString().padStart(2, '0')];

    return result.join(':');
};
