import { CustomImage } from 'components/common/CustomImg/styles';
import { hashtagFamily } from 'components/common/ModalProducts/constants';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { grey8, grey9, white } from 'constants/styles/colors';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { PaddingLeft, Sizes } from 'types/interfaces';
import { inputHeight, searchBorderRadius, searchPaddingLeft, widthSearch } from './constants';

interface SearchInputProps extends Sizes, PaddingLeft {}

export const StyledSearchInput = styled.input<SearchInputProps>`
    height: ${inputHeight};
    outline: none;
    width: ${({ width }) => width || widthSearch};
    background: ${grey8};
    border-radius: ${searchBorderRadius};
    padding: 15px 5px 15px ${({ paddingLeft }) => paddingLeft || searchPaddingLeft};
    border: 0;
    font-family: ${hashtagFamily};
    //TODO: font-family
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

export const SearchWrapper = styled(Section)`
    ${isMobile &&
    css`
        flex-direction: column;
        align-items: flex-start;
    `};
`;

export const SearchParametersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;

    ${isMobile &&
    css`
        width: 100%;
    `};
`;

export const CheckboxFilterWrapper = styled.div`
    margin-left: 25px;
    margin-top: 0;
    ${isMobile &&
    css`
        margin-left: 0;
        margin-top: 25px;
    `};
`;

interface OptionProps {
    onClick?: () => void;
    active?: boolean;
}

export const Option = styled.div<OptionProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid ${grey9};
    padding: 19px;
    transition: background-color 0.3s;

    &:last-child {
        border-bottom: none;
    }

    :hover {
        cursor: pointer;
        background-color: #f4f4f4;
    }
    ${CustomImage} {
        opacity: ${({ active }) => (active ? 1 : 0)};
    }
`;

export const OptionsWrapper = styled.div`
    width: 100%;
    height: 102px;
    background: ${white};
    border: 1px solid ${grey9};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 22px;
    margin-left: 0px;

    @media (min-width: 376px) {
        width: 185px;
    }
`;
