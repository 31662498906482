import styled, { css } from 'styled-components';
import { ContentWrapper } from 'components/grid/wrappers/ContentWrapper';
import { isMobile } from 'react-device-detect';

export const WrapperCard = styled(ContentWrapper)`
    ${!isMobile &&
    css`
        background: #f9f9f9;
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        padding: 32px;
        cursor: pointer;
    `};
`;

export const UsernameSpan = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
`;
