import filterIcon from 'assets/img/filter_icon.svg';
import { CustomImage } from 'components/common/CustomImg/styles';
import { searchBorderRadius } from 'components/common/SearchFilters/constants';
import { grey8, grey9, white } from 'constants/styles';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { AbsoluteLocation, Active, Sizes, Visibility } from 'types/interfaces';
import { hoveredColor } from './constants';

export interface SelectWrapperProps extends Sizes {
    hoverColor?: string;
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
    ${isMobile
        ? css<SelectWrapperProps>`
              ${({ height }) => height && `height: ${height}`};
              width: 20px;
              background-image: url('${filterIcon}');
              background-position: center;
              background-repeat: no-repeat;
          `
        : css<SelectWrapperProps>`
              position: relative;
              ${({ height }) => height && `height: ${height}`};
              ${({ width }) => width && `width: ${width}`};
              display: flex;
              justify-content: center;
              border-radius: ${searchBorderRadius};
              padding: 0 17px 0 24px;
              background-color: ${grey8};
              transition: background-color 0.3s;

              :hover {
                  background-color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#efefef')};
                  cursor: pointer;
              }
          `}
`;

export const StyledSelect = styled.select<SelectWrapperProps>`
    appearance: none;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    opacity: 0;
`;

export const StyledOption = styled.option``;

export const ItemSpan = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
`;

interface ItemsAbsoluteWrapper extends Visibility, Pick<AbsoluteLocation, 'top'>, Pick<Sizes, 'width'> {}

export const ItemsAbsoluteWrapper = styled.div<ItemsAbsoluteWrapper>`
    ${isMobile
        ? css<ItemsAbsoluteWrapper>`
              position: fixed;
              ${({ top }) => top && `top: ${top}`};
              left: 24px;
              right: 24px;
          `
        : css<ItemsAbsoluteWrapper>`
              position: absolute;
              right: 0;
              ${({ top }) => top && `top: ${top}`};
              ${({ width }) => width && `width: ${width}`};
          `};
    background-color: ${white};
    border: 1px solid ${grey9};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: auto;
    z-index: 100;
    ${({ visible }) => !visible && 'visibility: hidden;'};
`;

export const ItemWrapper = styled.div<Active>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    padding: 19px;
    background-color: ${white};
    transition: background-color 0.3s;

    :hover {
        background-color: ${hoveredColor};
        cursor: pointer;
    }
    ${CustomImage} {
        opacity: ${({ active }) => (active ? 1 : 0)};
    }
`;
