export const colors = {
    white: '#FFFFFF',
    black: '#000000',
    green: '#00FF44',
    greenLight: '#33FF6B',
    gray: '#979797',
    grayLight: '#939393'
} as const;

// global
export const white = colors.white;
export const black = colors.black;
export const green = colors.green;
export const scrollBarGrey = 'rgba(9, 30, 66, 0.08)';

export const grey2 = '#C2C2C2';
export const grey3 = '#E3E3E3';
export const grey5 = '#E5E5E5';
export const grey6 = '#f7f7f7';
export const grey7 = '#E8E8E8';
export const grey8 = '#F6F6F6';
export const grey9 = '#eeeeee';

export type Colors = typeof colors;
export type Color = keyof typeof colors;
