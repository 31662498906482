import { secondaryPadding } from 'constants/styles';

export const wrapperMargin = secondaryPadding;
// export const wrapperWidth = '212px';
export const wrapperWidth = `calc(50% - ${wrapperMargin} / 2)`;
export const wrapperHeight = '360px';
export const wrapperBorderRadius = '13px';
export const wrapperBorderWidth = '1px';
export const wrapperPadding = '16px';

export const featuresWrapperBorderRadius = '15px';
export const featuresWrapperPadding = '5px';

export const playImgDiameter = '14px';
