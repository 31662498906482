import {
    featuresWrapperBorderRadius,
    featuresWrapperPadding,
    wrapperBorderRadius,
    wrapperBorderWidth,
    wrapperHeight,
    wrapperMargin,
    wrapperPadding,
    wrapperWidth
} from 'components/layouts/cards/TrendingVideoCard/constants';
import { flexCenter, flexStart, grey5, white } from 'constants/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { pixelsAddition } from 'utils/parsers';

export const WrapperLink = styled(Link)`
    position: relative;
    ${flexStart};
    justify-content: flex-end;
    flex-direction: column;
    width: ${wrapperWidth};
    height: ${wrapperHeight};
    border-radius: ${wrapperBorderRadius};
    border: ${wrapperBorderWidth} solid ${grey5};
    margin-right: ${wrapperMargin};
    overflow: hidden;

    &:nth-child(2n) {
        margin-right: 0;
    }

    margin-bottom: ${wrapperMargin};
    padding: ${wrapperPadding};
`;

export const FeaturesWrapper = styled.div`
    position: relative;
    ${flexCenter};
    flex-direction: row;
    border-radius: ${featuresWrapperBorderRadius};
    background-color: rgba(0, 0, 0, 0.3);
    padding: ${featuresWrapperPadding};
    padding-right: ${pixelsAddition(featuresWrapperPadding, '2px')};
`;

export const AbsoluteImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${wrapperBorderRadius};
    object-fit: cover;
    z-index: 1;
`;

export const ViewsText = styled.span`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: ${white};
`;
