import ClearIcon from 'assets/img/clear_button_icon.svg';
import SearchIcon from 'assets/img/search.svg';
import selectIcon from 'assets/img/select_icon.svg';
import { CustomImage } from 'components/common/CustomImg/styles';
import { Hashtag } from 'components/common/Hashtag';
import { WrapperHashtagLine } from 'components/common/Hashtag/styles';
import { Img } from 'components/common/imageComponents/Image/styles';
import { Select } from 'components/common/Input/Select';
import { ItemSpan } from 'components/common/Input/Select/styles';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { FlexGrow, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { RelativeWrapper } from 'components/grid/wrappers/RelativeWrapper';
import { useStore } from 'effector-react';
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { searchEffects, searchEvents, searchStores } from 'stores/search';
import { IsCreatorSearch } from 'types/interfaces';
import { noop } from 'utils/common';
import { fontWeightSearch, hashtagMarginBottom, searchParameters, wrapperHashtagsMarginBottom } from './constants';
import { Option, OptionsWrapper, SearchParametersWrapper, SearchWrapper, StyledSearchInput } from './styles';

const { setHashtags, getVideosByHashtags, setDefaultCreators } = searchEvents;

interface SearchInputProps {
    value: string;
    onChange: (value: string) => void;
    onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const SearchInput = ({ value, onChange, onKeyUp }: SearchInputProps) => {
    const onClear = () => onChange('');
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

    return (
        <RelativeWrapper>
            <AbsoluteWrapper left="13px" top="13px" zIndex="1">
                <Img height="24px" src={SearchIcon} width="24px" />
            </AbsoluteWrapper>
            {value && (
                <AbsoluteWrapper right="17px" top="16px" zIndex="1" onClick={onClear}>
                    <Img pointer height="16px" src={ClearIcon} width="16px" />
                </AbsoluteWrapper>
            )}
            <StyledSearchInput placeholder="Search Here" value={value} onChange={onInputChange} onKeyUp={onKeyUp} />
        </RelativeWrapper>
    );
};

interface SearchFiltersProps extends IsCreatorSearch {
    activeSearchParameter: string;
    onChange: (activeSearchParameter: string) => void;
}

export const SearchFilters = ({ isCreatorSearch, activeSearchParameter, onChange }: SearchFiltersProps) => {
    const [value, setValue] = useState('');
    const [optionVisibility, setOptionVisibility] = useState(false);
    const [optionValue, setOptionValue] = useState(searchParameters[0]);
    const hashtags = useStore(searchStores.hashtags);
    // const [isVerified, setIsVerified] = useState(false);

    const clearValue = (value: string) => {
        const hashtagValue = value.replaceAll(' ', '');

        if (hashtagValue !== '') {
            setHashtags(hashtagValue);
            setValue('');
        }
    };

    const videosSearchHandlerKey = (e: KeyboardEvent<HTMLInputElement>) => {
        e.key === ' ' && clearValue(value);
        if (e.key === 'Enter') {
            clearValue(value);
            getVideosByHashtags(null);
        }
    };

    const videosHandlerSearch = (value: string) => setValue(value);

    const creatorHandlerSearch = (value: string) => {
        setValue(value);
        value ? searchEffects.getCreatorByUsername({ searchText: value }) : setDefaultCreators();
    };

    const onSearchChange = (parameters: string) => {
        onChange(parameters);
        setValue('');
    };

    // useEffect(() => {
    //     isCreatorSearch && value && searchEffects.getCreatorByUsername({ searchText: value });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isVerified]);

    const handlerKey = isCreatorSearch ? noop : videosSearchHandlerKey;
    const handlerSearch = isCreatorSearch ? creatorHandlerSearch : videosHandlerSearch;

    return (
        <>
            <SearchWrapper alignCenter marginBottom="16px" marginTop="35px">
                <SearchParametersWrapper>
                    <FlexGrow flexDirection="row" marginRight={isMobile ? '16px' : '8px'}>
                        <SearchInput value={value} onChange={handlerSearch} onKeyUp={handlerKey} />
                    </FlexGrow>
                    <Select
                        activeSelector={activeSearchParameter}
                        name="search"
                        selector={searchParameters}
                        setOptionVisibility={() => {
                            setOptionVisibility(!optionVisibility);
                        }}
                        onChange={onSearchChange}
                    />
                </SearchParametersWrapper>
            </SearchWrapper>
            {isMobile && optionVisibility ? (
                <Section alignCenter justifyEnd>
                    <OptionsWrapper>
                        {searchParameters.map(it => (
                            <Option
                                key={it}
                                active={it === optionValue}
                                onClick={() => {
                                    onSearchChange(it);
                                    setOptionVisibility(false);
                                    setOptionValue(it);
                                }}
                            >
                                <ItemSpan>{it}</ItemSpan>
                                <CustomImage alt="Select Icon" height="12px" src={selectIcon} width="13px" />
                            </Option>
                        ))}
                    </OptionsWrapper>
                </Section>
            ) : null}
            {!!hashtags.length && (
                <MarginWrapper marginBottom={wrapperHashtagsMarginBottom}>
                    <WrapperHashtagLine flexWrap>
                        {hashtags?.map(({ value, id }) => (
                            <Hashtag
                                key={id}
                                hasPrefix
                                isSearch
                                removeIcon
                                fontWeight={fontWeightSearch}
                                id={id}
                                marginBottom={hashtagMarginBottom}
                            >
                                {value}
                            </Hashtag>
                        ))}
                    </WrapperHashtagLine>
                </MarginWrapper>
            )}
        </>
    );
};
