import styled from 'styled-components';
import { fonts } from 'constants/styles';

export const Title = styled.h3`
    font-family: ${fonts.sfuidSemibold};
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
`;
