import { desktopHeaderHeight } from 'components/common/Header/constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100% - ${desktopHeaderHeight});
    display: flex;
    justify-content: center;
    align-items: center;
`;
