import styled from 'styled-components';
import { grey6 } from 'constants/styles';

export const TopCreatorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px;
    background: ${grey6};
    border-radius: 16px;
    width: 100%;
`;
