import { flexCenter } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { ImgProps, ImgWrapperProps } from './types';

export const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    background-size: auto;
`;

export const CustomImage = styled.img<ImgProps>`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    ${flexCenter};
    ${({ borderRadius }) =>
        borderRadius &&
        css`
            border-radius: ${borderRadius};
            overflow: hidden;
        `};
    ${({ rotate }) => (rotate ? `transform: rotate(${rotate}deg);` : ``)};
    ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')};
    ${({ center }) => center && 'margin: auto;'};
`;

export const ImgWrapper = styled.div<ImgWrapperProps>`
    ${flexCenter};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    ${({ center }) => center && 'margin: auto;'};
`;
