import { MobileWarning } from 'components/common/blocks/MobileWarning';
import { MobileHeader } from 'components/common/videoComponents/VideoHeader';
import { FlexGrow } from 'components/grid/wrappers/FlexWrapper';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { MainLayout } from 'components/layouts/MainLayout';
import { homeUrl, mainFeedUrl, profileUrl } from 'constants/routes';
import { useStore } from 'effector-react';
import { withAdaptiveComponent } from 'hocs/withAdaptiveComponent';
import { LoadingPage } from 'pages/LoadingPage';
import { ProfilePage } from 'pages/Main/ProfilePage';
import { VideoPage } from 'pages/Main/VideoPage';
import React, { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { commonEvents, commonStores } from 'stores/common';
import { playlistEffects, playlistStores } from 'stores/playlist';
import { searchEvents } from 'stores/search';
import { MainSearchParams } from 'types';
import { setAppLink } from 'utils/common';
import { parseSearchParams } from 'utils/parsers';

const { setDefaultHashtag, setDefaultCreators } = searchEvents;

interface ParamsProps {
    id: string;
}

interface Props {
    width: number;
    height: number;
}

const MainComponent: FC<Props> = ({ width, height }) => {
    const { id } = useParams<ParamsProps>();
    const location = useLocation();
    const { userId, videoId } = parseSearchParams<MainSearchParams>(location.search);
    const mainVideo = useStore(playlistStores.mainVideo);
    const error = useStore(commonStores.error);
    const loading = useStore(playlistStores.initialLoading);
    const history = useHistory();

    const currentVideoId = videoId || id;

    useEffect(() => {
        setAppLink(location.pathname, location.search);

        setDefaultHashtag();
        setDefaultCreators();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentVideoId) {
            playlistEffects.getMainVideo(currentVideoId);
        }
    }, [currentVideoId]);

    useEffect(() => {
        if (!error.hasOwnProperty('isSuccess')) return;
        if (!error?.isSuccess) {
            commonEvents.setErrorDefault();
            history.push(homeUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (!location.search && (location.pathname === mainFeedUrl || location.pathname === profileUrl))
            history.push(homeUrl);
        if (location.search && !userId && !currentVideoId) history.push(homeUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (userId) {
        return (
            <ContentLayout>
                <ProfilePage userId={userId} />
            </ContentLayout>
        );
    }

    return (
        <ContentLayout>
            {loading ? (
                <LoadingPage />
            ) : mainVideo?.id ? (
                <VideoPage video={mainVideo} />
            ) : (
                <>
                    {isMobile && <MobileHeader height={height} type="search" width={width} />}
                    <FlexGrow alignCenter justifyCenter flexGrow="2">
                        <MobileWarning />
                    </FlexGrow>
                </>
            )}
        </ContentLayout>
    );
};

const AdaptiveMainComponent = withAdaptiveComponent(MainComponent);

export const Main = () => (
    <MainLayout isNewHeader>
        <AdaptiveMainComponent />
    </MainLayout>
);
