import { DesktopLoader } from 'components/common/Loader';
import {
    LatestVideoTitle,
    SearchResultWrapper,
    StyledLatestVideo,
    UserCardWrapper
} from 'components/common/videoComponents/SearchResult/styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { LatestVideoCard } from 'components/layouts/cards/LatestVideoCard';
import { SearchedUserCard } from 'components/layouts/cards/SearchedUserCard';
import { homeUrl } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { playlistStores } from 'stores/playlist';
import { searchEvents, searchStores } from 'stores/search';
import { trendingsEffects, trendingsStores } from 'stores/trendings';
import { Hashtag, IsCreatorSearch } from 'types';
import { setAppLink } from 'utils/common';
import { parseSearchParams } from 'utils/parsers';

interface SearchResultProps extends IsCreatorSearch {}

export const SearchResult = ({ isCreatorSearch }: SearchResultProps) => {
    const videos = useStore(trendingsStores.videos);
    const users = useStore(trendingsStores.users);
    const initialLoading = useStore(trendingsStores.initialLoading);
    const loading = useStore(playlistStores.loading);
    const refLatestVideo = useRef<HTMLDivElement>(null);
    const [loadingSearch, videosSearchResult, creatorsSearchResult] = useStore(searchStores.searchStore);
    const location = useLocation();
    const { hashtag } = parseSearchParams<Hashtag>(location.search);
    const history = useHistory();

    useEffect(() => {
        setAppLink(location.pathname, location.search);
        trendingsEffects.getTrendings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        hashtag && searchEvents.setSearchedHashtag(hashtag);
    }, [hashtag]);

    useEffect(() => {
        if (location.search && !hashtag) history.push(homeUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <StyledLatestVideo ref={refLatestVideo}>
            {!isMobile && <LatestVideoTitle>Trending {isCreatorSearch ? 'creators' : 'videos'}</LatestVideoTitle>}
            <SearchResultWrapper>
                {loading || initialLoading || loadingSearch ? (
                    <Section alignCenter justifyCenter>
                        <DesktopLoader />
                    </Section>
                ) : isCreatorSearch ? (
                    creatorsSearchResult.length ? (
                        creatorsSearchResult.map(({ title, thumbnailUrl, isTrusted, entityId }) => (
                            <UserCardWrapper key={title}>
                                <SearchedUserCard
                                    id={entityId}
                                    isTrusted={isTrusted}
                                    profileImageUrl={thumbnailUrl}
                                    username={title}
                                />
                            </UserCardWrapper>
                        ))
                    ) : (
                        users.map(({ userId, username, profileImageUrl, isTrusted }) => (
                            <UserCardWrapper key={username}>
                                <SearchedUserCard
                                    id={userId}
                                    isTrusted={isTrusted}
                                    profileImageUrl={profileImageUrl}
                                    username={username}
                                />
                            </UserCardWrapper>
                        ))
                    )
                ) : videosSearchResult.length ? (
                    videosSearchResult.map(({ id, profileImageUrl, streaming, engagement }) => (
                        <LatestVideoCard
                            key={id}
                            id={id}
                            profileImageUrl={profileImageUrl}
                            thumbnailUrl={streaming?.screenGrabUrl}
                            viewCount={engagement?.views}
                        />
                    ))
                ) : (
                    videos.map(({ videoId = '', ...item }) => <LatestVideoCard key={videoId} {...item} id={videoId} />)
                )}
            </SearchResultWrapper>
        </StyledLatestVideo>
    );
};
