import appStoreImageSrc from 'assets/img/app-store-img.png';
import googlePlayImageSrc from 'assets/img/google-play-img.png';
import logoSrc from 'assets/img/logo.png';
import { Column, Row } from 'components/grid/wrappers/FlexWrapper';
import { androidAppStoreLink, homeUrl, iosAppStoreLink } from 'constants/routes';
import React from 'react';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { Container, ExternalLink, StyledLink, Title, Wrapper } from './styles';

const StoreLink = ({ type = 'ios' }: { type: 'ios' | 'android' }) => {
    const typeIsIos = type === 'ios';

    return (
        <ExternalLink
            href={typeIsIos ? iosAppStoreLink : androidAppStoreLink}
            rel="noopener noreferrer"
            target="_blank"
        >
            <img
                alt={typeIsIos ? 'App store' : 'Google play'}
                height={20}
                src={typeIsIos ? appStoreImageSrc : googlePlayImageSrc}
            />
        </ExternalLink>
    );
};

export const NewHeader = () => {
    const isMobileView = isTablet || isMobile;

    return (
        <Wrapper>
            <Container>
                <Row alignCenter>
                    <StyledLink to={homeUrl}>
                        <img alt="Logo" src={logoSrc} width={70} />
                    </StyledLink>

                    {!isMobileView && (
                        <Column marginLeft="40px">
                            <Title>Collab with brands you love & get rewarded!</Title>
                        </Column>
                    )}
                </Row>

                {isMobileView ? (
                    <StoreLink type={isIOS ? 'ios' : 'android'} />
                ) : (
                    <Row alignCenter justifyEnd>
                        <StoreLink type="ios" />

                        <Column marginLeft="16px">
                            <StoreLink type="android" />
                        </Column>
                    </Row>
                )}
            </Container>
        </Wrapper>
    );
};
