import defaultAvatar from 'assets/defaults/default_avatar.svg';
import { LogoLink } from 'components/common/Header/styles';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { LogoImg } from 'components/common/imageComponents/LogoImg';
import { videoFooterWidth } from 'components/common/videoComponents/VideoFooter/constants';
import {
    getLinkApp,
    headerHeight,
    logoHeight,
    setGTMEvents,
    usernameWrapperHeight
} from 'components/common/videoComponents/VideoHeader/constants';
import {
    Description,
    Header,
    LinkApp,
    MainMobileHeader,
    UsernameText,
    UsernameWrapper
} from 'components/common/videoComponents/VideoHeader/styles';
import { MobileProps } from 'components/common/videoComponents/VideoHeader/types';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { colors, quaternaryPadding, secondaryPadding } from 'constants/styles';
import React from 'react';
import { searchEvents } from 'stores/search';
import { ImgProperties } from 'types';
import { pixelsAddition } from 'utils/parsers';

interface Props extends ImgProperties {
    username: string;
}

interface HeaderContentProps {
    linkAppBackgroundColor?: string;
    descriptionColor?: string;
    linkFontWeight?: string;
}

const HeaderContent = ({ descriptionColor, linkAppBackgroundColor, linkFontWeight }: HeaderContentProps) => {
    const handleClick = () => searchEvents.setDefaultHashtag();
    return (
        <Section alignCenter justifyBetween noWrap>
            <LogoLink to="/" onClick={handleClick}>
                <LogoImg height={logoHeight} />
            </LogoLink>

            <Row alignCenter height="100%" marginLeft="10px">
                <Description color={descriptionColor}>Download the App for free</Description>
                <LinkApp
                    backgroundColor={linkAppBackgroundColor}
                    fontWeight={linkFontWeight}
                    href={getLinkApp()}
                    target="_blank"
                    onClick={setGTMEvents()}
                >
                    Get App
                </LinkApp>
            </Row>
        </Section>
    );
};

export const MobileHeader = ({ height, width, type = 'video' }: MobileProps) => {
    if (type === 'video') {
        return (
            <Header height={height} width={width}>
                <HeaderContent linkFontWeight="600" />
            </Header>
        );
    } else {
        return (
            <MainMobileHeader height={height} width={width}>
                <HeaderContent
                    descriptionColor={colors.grayLight}
                    linkAppBackgroundColor={colors.green}
                    linkFontWeight="600"
                />
            </MainMobileHeader>
        );
    }
};

export const VideoHeader = ({ src, username }: Props) => (
    <AbsoluteWrapper
        left={secondaryPadding}
        top={pixelsAddition(quaternaryPadding, headerHeight)}
        width={videoFooterWidth}
    >
        <Section justifyEnd marginBottom="0">
            <UsernameWrapper>
                <UsernameText>{username}</UsernameText>
                <AvatarImg hasBorder diameter={usernameWrapperHeight} src={src || defaultAvatar} />
            </UsernameWrapper>
        </Section>
    </AbsoluteWrapper>
);
