import loaderImg from 'assets/img/load.gif';
import verifiedIcon from 'assets/img/verifiedIconNew.svg';
import volumeXIcon from 'assets/img/volume-x.svg';
import volumeIcon from 'assets/img/volume.svg';
import { Img } from 'components/common/CustomImg/styles';
import { Image } from 'components/common/imageComponents/Image';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { userUrl } from 'constants/routes';
import { Timeline } from 'pages/Main/VideoPage/Timeline';
import { StyledAvatar, VisitButton } from 'pages/Main/VideoPage/styles';
import React, { FC, MouseEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import {
    BgWrapper,
    ButtonWrapper,
    ControlsWrapper,
    DurationText,
    HlsPlayer,
    IconButton,
    MobileControls,
    UserWrapper,
    Wrapper
} from './styles';
import { getPlayerTime } from './utils';

interface Props {
    isPlaying: boolean;
    video: YEAY.PlaylistSingleVideoResponse;
    onClick?: (e: MouseEvent<HTMLVideoElement>) => void;
    visitLink: string;
}

export const Player: FC<Props> = ({ isPlaying, video, onClick, visitLink }) => {
    const playerRef = useRef<HTMLVideoElement>(null);

    const [totalDuration, setTotalDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [volume, setVolume] = useState(0);

    const isMuted = volume === 0;

    const clearStates = () => {
        setTotalDuration(0);
        setCurrentTime(0);
        setIsLoaded(false);
        setHasError(false);
    };

    const handleVideoLoaded = (e: SyntheticEvent<HTMLVideoElement>) => {
        setIsLoaded(true);
        setTotalDuration(e.currentTarget.duration || 0);
    };

    const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement>) => {
        setCurrentTime(e.currentTarget.currentTime || 0);
    };

    const setNewTime = (value: number) => {
        if (playerRef.current) {
            playerRef.current.currentTime = value;
        }
    };

    const toggleVolume = () => {
        setVolume(prev => (prev === 0 ? 1 : 0));
    };

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        if (!isPlaying) {
            clearStates();
        }
    }, [isPlaying]);

    if (!video.streaming?.hlsUrl || !isPlaying) {
        return null;
    }

    return (
        <Wrapper>
            {!isLoaded && (
                <BgWrapper>
                    <Image height="100px" src={loaderImg} width="100px" />
                </BgWrapper>
            )}

            {!hasError && (
                <HlsPlayer
                    autoPlay
                    loop
                    playsInline
                    height="100%"
                    muted={isMuted}
                    playerRef={playerRef}
                    poster={video.streaming?.screenGrabUrl || undefined}
                    src={video.streaming?.hlsUrl || ''}
                    width="100%"
                    onClick={onClick}
                    onError={() => setHasError(true)}
                    onLoadedData={handleVideoLoaded}
                    onTimeUpdate={handleTimeUpdate}
                />
            )}

            {isLoaded && !isMobile && (
                <ControlsWrapper>
                    <Section alignCenter justifyBetween noWrap marginBottom="4px">
                        <Timeline currentTime={currentTime} totalDuration={totalDuration} onChange={setNewTime} />
                        <Row alignCenter noWrap marginLeft="12px">
                            <DurationText>
                                {getPlayerTime(currentTime)}/{getPlayerTime(totalDuration)}
                            </DurationText>
                        </Row>
                        <Row alignCenter noWrap marginLeft="8px">
                            <IconButton onClick={toggleVolume}>
                                {isMuted ? (
                                    <Img height="22px" src={volumeXIcon} width="22px" />
                                ) : (
                                    <Img height="22px" src={volumeIcon} width="22px" />
                                )}
                            </IconButton>
                        </Row>
                    </Section>
                </ControlsWrapper>
            )}
            {isLoaded && isMobile && (
                <>
                    <MobileControls>
                        <Section alignCenter justifyBetween noWrap>
                            <Row alignCenter noWrap>
                                <IconButton onClick={toggleVolume}>
                                    {isMuted ? (
                                        <Img height="22px" src={volumeXIcon} width="22px" />
                                    ) : (
                                        <Img height="22px" src={volumeIcon} width="22px" />
                                    )}
                                </IconButton>
                            </Row>
                            <Link to={`${userUrl}${video.ownerId}`}>
                                <Row alignCenter>
                                    <UserWrapper alignCenter>
                                        <Row marginRight="6px">@{video.username}</Row>
                                        {video.isTrusted && (
                                            <Row marginRight="6px">
                                                <Img height="15px" src={verifiedIcon} width="15px" />
                                            </Row>
                                        )}
                                    </UserWrapper>
                                    <StyledAvatar src={video.profileImageUrl} />
                                </Row>
                            </Link>
                        </Section>
                    </MobileControls>
                    <ButtonWrapper>
                        <VisitButton href={visitLink} rel="noopener noreferrer" target="_blank">
                            Visit Website
                        </VisitButton>
                    </ButtonWrapper>
                </>
            )}
        </Wrapper>
    );
};
