import { Content, Loading } from 'pages/Main/styles';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

const headerHeight = '62px';

export const VideoWrapper = styled.div`
    width: 100%;
    height: calc(100% - ${headerHeight});
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Content} {
        display: flex;
        height: auto;
    }

    ${Loading} {
        display: none;
    }

    ${isMobile &&
    css`
        padding-bottom: 0;
        padding-top: 0;
        height: 100%;

        ${Content} {
            @media (orientation: landscape) {
                filter: blur(40px);
            }
        }

        ${Loading} {
            width: 200px;
            height: 200px;
        }
    `}
`;
