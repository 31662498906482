import { desktopVideosLimit, itemsPerLoad } from 'constants/defaults/playlist';
import { videoBlockTitle } from 'constants/stores';
import { createEvent, createStore } from 'effector';
import { API } from 'services';
import { getTrendingsCancelToken } from 'stores/trendings';
import { createNotifyingEffect } from 'utils/common';

const updateInitialLoading = createEvent();
const initialLoading = createStore(false).on(updateInitialLoading, state => !state);

const updateAdditionalLoading = createEvent();
const additionalLoading = createStore(false).on(updateAdditionalLoading, state => !state);

const updateLoading = createEvent();
const loading = createStore(false).on(updateLoading, state => !state);

const setTitle = createEvent<string>();
const rightSideVideosTitle = createStore('Trending videos').on(setTitle, (_, newState) => newState);

export const getMainVideo = createNotifyingEffect({
    handler: async (id: string) => {
        try {
            updateInitialLoading();
            const data = await API.playlist.get({ playlistVideoId: id });
            updateInitialLoading();

            return data || {};
        } catch (error) {
            updateInitialLoading();
            return {};
        }
    }
});

export const getVideosByTrending = createNotifyingEffect({
    handler: async (data: Omit<YEAY.QueryPlaylistVideosRequest, 'pageIndex' | 'limit'>) => {
        try {
            getTrendingsCancelToken && getTrendingsCancelToken.cancel();

            updateLoading();
            const { totalRecords, items } = await API.playlist.queryByFilter({
                pageIndex: 0,
                limit: desktopVideosLimit,
                ...data
            });
            updateLoading();

            data?.byHashtag ? setTitle('Videos by hashtag ' + data.byHashtag) : setTitle(videoBlockTitle);
            return { totalRecords, items };
        } catch (error) {
            updateLoading();
            return {};
        }
    }
});

const mainVideo = createStore<YEAY.PlaylistSingleVideoResponse>({})
    .on(getMainVideo.doneData, (_, newState) => newState)
    .on(getVideosByTrending.doneData, (_, { items }) => items?.[0] || {});
// mainVideo.watch(
//     ({ id }) => id && videoEffects.registerEventVideo({ videoId: id, sessionId: userStores.sessionId.getState() })
// );
const setMainVideoCreatorId = createEvent<string>();
const mainVideoCreatorId = createStore('')
    .on(getMainVideo.doneData, (_, { ownerId }) => ownerId || '')
    .on(setMainVideoCreatorId, (_, videoCreatorId) => videoCreatorId);

export const getAdditionalMobileCreatorVideos = createNotifyingEffect({
    handler: async (pageIndex: number) => {
        try {
            updateAdditionalLoading();
            const { totalRecords, items } = await API.playlist.queryByFilter({
                pageIndex,
                limit: itemsPerLoad,
                byCreatorId: mainVideoCreatorId.getState()
            });
            updateAdditionalLoading();

            return { totalRecords, items: items?.filter(({ id }) => id !== mainVideo.getState().id) };
        } catch {
            updateAdditionalLoading();

            return {};
        }
    }
});

const mobileCreatorPlaylist = createStore<YEAY.QueryPlaylistVideosResponse>({})
    .on(getMainVideo.doneData, (state, item) => ({
        ...state,
        totalRecords: 1,
        items: [item]
    }))
    .on(getAdditionalMobileCreatorVideos.doneData, (state, { items, totalRecords }) => ({
        ...state,
        totalRecords,
        items: state?.items?.length
            ? items?.length
                ? [...state.items, ...items]
                : state.items
            : items?.length
            ? items
            : []
    }));

// export const loadPlaylistVideo = createNotifyingEffect({
//     handler: async (playerId?: string) => {
//         const playlistVideo = await API.playlist.get({ playlistVideoId: playerId });

//         return playlistVideo || '';
//     }
// });

export const loadPlaylist = createNotifyingEffect({
    handler: async (data: YEAY.CreatePlaylistRequest) => {
        try {
            updateInitialLoading();
            const playlist = await API.playlist.query(data);
            updateInitialLoading();

            return playlist.items || [];
        } catch {
            updateInitialLoading();

            return [];
        }
    }
});

export const loadAdditionalPlaylistItems = createNotifyingEffect({
    handler: async () => {
        try {
            updateInitialLoading();
            const playlist = await API.playlist.query({
                limit: itemsPerLoad
            });
            updateInitialLoading();

            return playlist.items || [];
        } catch {
            updateInitialLoading();

            return [];
        }
    }
});

export const playlist = createStore({}).on(loadPlaylist.doneData, (_, playlist) => playlist);

export const playlistItems = createStore<YEAY.PlaylistVideoResponse[]>([]).on(
    loadPlaylist.doneData,
    (state, playlist: YEAY.PlaylistVideoResponse[]) => [...state, ...playlist]
);

const setCurrentActiveVideo = createEvent<string>();
const currentActiveVideo = createStore('').on(setCurrentActiveVideo, (_, id) => id);

// const loadVideo = createNotifyingEffect({
//     handler: async (videoId: string) => await API.playlist.get({ playlistVideoId: videoId })
// });

// export const mainLoad = createNotifyingEffect({
//     handler: async (videoId: string) => {
//         const video = await loadVideo(videoId);
//         await videoEffects.loadPlaylistCreator({
//             byCreatorId: video.ownerId,
//             limit: MAX_VIDEO_COUNT,
//             pageIndex: 0
//         });
//         await loadPlaylist({
//             limit: MAX_VIDEO_COUNT
//             //pageIndex: 0
//         });
//     }
// });

// const loadVideoSlideChange = createNotifyingEffect({
//     handler: async (videoId: string) => await API.video.registerVideoAsViewed({ videoId })
// });

// const videoSlideId = createStore({}).on(loadVideoSlideChange.doneData, (_, id) => id);

// const setLoadedVideo = createEvent<boolean>();

// const video = createStore<YEAY.PlaylistSingleVideoResponse>({}).on(loadVideo.doneData, (_, newState) => newState);

// const loadedVideo = createStore(false).on(setLoadedVideo, (_state, payload) => payload);

// const isLoadingVideo = createStore(true)
//     .on(loadVideo, () => true)
//     .on(loadVideo.done, () => false)
//     .on(loadVideo.fail, () => false);

export const playlistEvents = { setTitle, setCurrentActiveVideo, setMainVideoCreatorId };
export const playlistEffects = {
    //loadVideo,
    loadPlaylist,
    // setLoadedVideo,
    // loadVideoSlideChange,
    // mainLoad,
    getMainVideo,
    getVideosByTrending,
    getAdditionalMobileCreatorVideos
};
export const playlistStores = {
    // video,
    // loadedVideo,
    currentActiveVideo,
    rightSideVideosTitle,
    mainVideo,
    additionalLoading,
    mobileCreatorPlaylist,
    mainVideoCreatorId,
    playlist,
    playlistItems,
    loading,
    // isLoadingVideo,
    // videoSlideId,
    initialLoading
};
