import selectIcon from 'assets/img/select_icon.svg';
import { CustomImg } from 'components/common/CustomImg';
import { ArrowImg } from 'components/common/imageComponents/ArrowImg';
import { itemShift, selectMarginBottom } from 'components/common/Input/Select/constants';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { useCloseClick } from 'hooks/closeClick';
import { useRefPosition } from 'hooks/getRefPosition';
import { useModal } from 'hooks/modal';
import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { ItemsAbsoluteWrapper, ItemSpan, ItemWrapper, SelectWrapper, SelectWrapperProps } from './styles';

export interface SelectProps extends SelectWrapperProps {
    selector: string[];
    activeSelector?: string;
    onChange: (item: string) => void;
    name: string;
    setOptionVisibility: () => void;
}

export const Select = ({
    onChange,
    setOptionVisibility,
    selector,
    activeSelector = selector[0],
    width = '150px',
    height = '50px'
}: SelectProps) => {
    const { visible, close, open } = useModal();
    const componentRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const selectPosition = useRefPosition(selectRef);

    const itemWidth = parseInt(width) + parseInt(itemShift) + 'px';
    const top = (isMobile ? selectPosition.y : 0) + parseInt(height) + parseInt(selectMarginBottom) + 'px';

    const openClick = () => open();
    const selectClick = (item: string) => {
        onChange(item);
        close();
    };

    useCloseClick(componentRef, close);

    return (
        <SelectWrapper
            ref={componentRef}
            height={height}
            width={width}
            onClick={() => {
                setOptionVisibility();
                visible ? close() : openClick();
            }}
        >
            {isMobile ? null : (
                <>
                    <Section ref={selectRef} alignCenter justifyBetween>
                        <ItemSpan>{activeSelector}</ItemSpan>
                        {selector.length !== 1 && <ArrowImg rotate={visible ? 180 : 0} />}
                    </Section>
                    <ItemsAbsoluteWrapper top={top} visible={visible} width={itemWidth}>
                        <Column>
                            {selector.map(item => (
                                <ItemWrapper
                                    key={item}
                                    active={item === activeSelector}
                                    onClick={() => selectClick(item)}
                                >
                                    <ItemSpan>{item}</ItemSpan>
                                    <CustomImg alt="Select Icon" height="12px" src={selectIcon} width="13px" />
                                </ItemWrapper>
                            ))}
                        </Column>
                    </ItemsAbsoluteWrapper>
                </>
            )}
        </SelectWrapper>
    );
};
