import RemoveIcon from 'assets/remove.svg';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components/macro';

export const StyledNotifications = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10;

    ${isMobile &&
    css`
        right: 0;
        padding-left: 10px;
        padding-right: 10px;
    `}
`;

export const Notification = styled.div`
    @keyframes show {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    width: 380px;
    padding: 16px 24px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: white;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.3), 0 6px 16px 0 rgba(0, 0, 0, 0.2), 0 9px 28px 8px rgba(0, 0, 0, 0.03);

    animation: show 0.3s;

    ${isMobile &&
    css`
        width: 300px;
    `}
`;

export const Header = styled.div`
    display: flex;
`;

export const Message = styled.div`
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 24px;
`;

export const Remove = styled.div`
    flex: none;

    width: 12px;
    height: 12px;

    background-image: url(${RemoveIcon});

    cursor: pointer;
`;

export const Description = styled.div`
    font-size: 14px;
`;
