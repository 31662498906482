import { prefix } from 'components/common/Hashtag/constants';
import { RemoveImg, Wrapper, WrapperProps } from 'components/common/Hashtag/styles';
import React, { FC } from 'react';
import { NoopClick, NoopType } from 'types';
import { useHistory } from 'react-router-dom';
import { searchEvents } from 'stores/search';
import removeImg from 'assets/removeBlack.svg';
import { mobileProductModalEvents } from 'stores/modal';
import { isMobile } from 'react-device-detect';
import { hashtagUrl } from 'constants/routes';

interface Props extends Omit<WrapperProps, 'pointer'>, NoopClick {
    hasPrefix?: boolean;
    children?: string;
    removeIcon?: boolean;
    removeClick?: NoopType;
    id?: string;
    isSearch?: boolean;
}

export const Hashtag: FC<Props> = ({ hasPrefix, children, removeIcon, isSearch, id, ...wrapperProps }) => {
    const history = useHistory();
    const handleClickHashtags = () => {
        //localStorage.setItem('hashtag', children as string);
        children && !isSearch && history.push(hashtagUrl + children.replace('#', ''));
        mobileProductModalEvents.closeModal();
    };
    const handleRemoveClick = () => id && searchEvents.deleteHashtags(id);

    if (isSearch) {
        if (!isMobile) {
            return (
                <Wrapper pointer={!removeIcon} {...wrapperProps}>
                    {removeIcon && <RemoveImg alt="remove" src={removeImg} onClick={handleRemoveClick} />}
                    {hasPrefix && prefix}
                    {children}
                </Wrapper>
            );
        }
        return (
            <Wrapper pointer onClick={handleRemoveClick} {...wrapperProps}>
                {removeIcon && <RemoveImg alt="remove" src={removeImg} />}
                {hasPrefix && prefix}
                {children}
            </Wrapper>
        );
    }

    return (
        <Wrapper pointer onClick={handleClickHashtags} {...wrapperProps}>
            {removeIcon && <RemoveImg alt="remove" src={removeImg} onClick={handleRemoveClick} />}
            {hasPrefix && prefix}
            {children}
        </Wrapper>
    );
};
