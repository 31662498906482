import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { BackDrop, Content, PopupStyled } from './styles';

export interface PopupProps {
    //isShow: boolean;
    //onClosePopup?: () => void;
    className?: string;
    height?: string;
}

export const Popup: FC<PopupProps> = ({ children, className, height = '60vh' }) => (
    <PopupStyled className={className} isMobile={isMobile}>
        <BackDrop />
        <Content height={height}>{children}</Content>
    </PopupStyled>
);
