import { DesktopLoader } from 'components/common/Loader';
import { TrendingVideoCard } from 'components/layouts/cards/TrendingVideoCard';
import { useStore } from 'effector-react';
import React from 'react';
import { playlistStores } from 'stores/playlist';
import { trendingsStores } from 'stores/trendings';
import { Content, RelatedVideoStyled, Title, MobileVideoWrapper } from './styles';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { isMobile } from 'react-device-detect';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { BlockTitle } from 'components/common/typography/BlockTitle';
import { LatestVideoCard } from 'components/layouts/cards/LatestVideoCard';
import { videoBlockTitle } from 'constants/stores';

export const TrendingVideos = () => {
    const title = useStore(playlistStores.rightSideVideosTitle);
    const videos = useStore(trendingsStores.videos);
    const initialLoading = useStore(trendingsStores.initialLoading);
    const loading = useStore(playlistStores.loading);
    const { profileImageUrl } = useStore(playlistStores.mainVideo);

    const handleClickVideo = (videoId: string) => {};
    // const date = new Date();
    // const time = date.getTime();
    // const percent = Number(((time - paramsVideo.time) / 1000 / paramsVideo.duration).toFixed(2));
    // videoEffects.registerEventVideo({
    //     videoId,
    //     sessionId: userStores.sessionId.getState()
    //     //viewDurationPercentage: percent > 1 ? 1 : percent
    // });
    if (isMobile) {
        return (
            <>
                <MarginWrapper marginBottom="20px">
                    <BlockTitle>{title}</BlockTitle>
                </MarginWrapper>
                <MobileVideoWrapper>
                    {videos.map(({ videoId = '', ...item }) => (
                        <LatestVideoCard
                            key={videoId}
                            {...item}
                            height="180px"
                            id={videoId}
                            profileImageUrl={title === videoBlockTitle ? profileImageUrl : undefined}
                            width="103px"
                        />
                    ))}
                </MobileVideoWrapper>
            </>
        );
    } else {
        return (
            <RelatedVideoStyled>
                <Content>
                    <Title>{title}</Title>
                    <Section justifyCenter>
                        {loading || initialLoading ? (
                            <DesktopLoader />
                        ) : (
                            videos.map(({ videoId = '', ...item }) => (
                                <TrendingVideoCard
                                    key={videoId}
                                    {...item}
                                    id={videoId}
                                    profileImageUrl={profileImageUrl}
                                    onClick={() => handleClickVideo(videoId)}
                                />
                            ))
                        )}
                    </Section>
                </Content>
            </RelatedVideoStyled>
        );
    }
};
