import {
    addedHeightToModalWrapper,
    nicknameWrapperMarginTop,
    wrapperBorderRadius,
    wrapperHeight,
    wrapperPadding
} from 'components/modals/MobileProductModal/constants';
import { fonts, scrollBarMobileMixin, white } from 'constants/styles';
import styled from 'styled-components';
import { MarginBottom, MarginLeft, MarginRight } from 'types';

interface WrapperProps {
    height: number;
}

interface UpProps {
    display: string;
}

export const Wrapper = styled.div<WrapperProps>`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${wrapperHeight};
    // border-top-left-radius: ${wrapperBorderRadius};
    // border-top-right-radius: ${wrapperBorderRadius};
    background: ${white};
    padding: ${wrapperPadding} 0;
    z-index: 19;
    overflow: auto;
    ${({ height }) =>
        height
            ? `padding-top: calc(((100vh - ${height}px) / 2) + ${nicknameWrapperMarginTop} + ${addedHeightToModalWrapper});`
            : ``};
    ${({ height }) => (height ? `padding-bottom: calc((100vh - ${height}px) / 2);` : ``)};
    ${scrollBarMobileMixin};
`;

export const WrapperPadding = styled.div`
    padding: 0 ${wrapperPadding};
`;

export const AbsoluteModalWrapper = styled.div<WrapperProps>`
    position: absolute;
    ${({ height }) => (height ? `top: calc((100vh - ${height}px) / 2 + 90px + ${addedHeightToModalWrapper});` : ``)};
    right: 10px;
`;

interface ModalProductTitleProps extends MarginLeft, MarginBottom {}

export const ModalProductTitle = styled.h3<ModalProductTitleProps>`
    font-family: ${fonts.sfuidSemibold};
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : ``)};
    ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom};` : ``)};
`;

export const TrendingWrapper = styled.div`
    margin-top: 20px;
`;

export const TrendingCreatorWrapper = styled.div`
    margin-top: 24px;
`;

export const TrendingVideoWrapper = styled.div`
    margin: 0 10px;
`;

interface MobileWrapperLineProps extends MarginLeft, MarginRight {
    height: string;
}

export const MobileWrapperLine = styled.div<MobileWrapperLineProps>`
    //overflow: auto;
    display: flex;
    overflow-y: scroll;
    height: ${({ height }) => height};
    ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : ``)};
    ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : ``)};
    ${scrollBarMobileMixin};
`;

export const MobileVideoWrapper = styled.div`
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    align-items: flex-start;
    ${scrollBarMobileMixin};
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const MoreIcon = styled.img`
    margin-right: 17px;
`;
