import { css } from 'styled-components';
import { colors, scrollBarGrey } from './colors';
import { isMobile } from 'react-device-detect';

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ellipsisMixin = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const disableDefaultButtonStyleMixin = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const scrollBarDesktopMixin = css`
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: ${scrollBarGrey};
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray};
        border-radius: 6px;
    }
`;

export const scrollBarMobileMixin = css`
    &::-webkit-scrollbar {
        width: 0px;
        //height: 0;
        background: transparent;
    }
    scrollbar-width: none;
`;

export const scrollBarMixin = css`
    ${isMobile ? scrollBarMobileMixin : scrollBarDesktopMixin};
`;
