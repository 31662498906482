import { scrollBarMixin } from 'constants/styles';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const LatestVideoTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
`;

export const SearchResultWrapper = styled.div`
    display: flex;
    //flex-grow: 1;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5px 20px 5px;

    @media (max-width: 338px) {
        justify-content: space-around;
    }
`;

export const StyledLatestVideo = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    ${scrollBarMixin};
`;

export const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UserCardWrapper = styled.div`
    margin-bottom: 8px;
    ${isMobile &&
    css`
        width: 100%;
        margin-bottom: 24px;
    `};
`;
