import { createEffect, createEvent, createStore } from 'effector';
import { playlistEffects } from 'stores/playlist';
import { API } from '../services';
import { commonEvents, commonStores } from 'stores/common';
import {
    calculateTrendingCreatorsScrollWidth,
    calculateTrendingHashtagsScrollWidth,
    isAllCreatorsVisible,
    isAllHashtagsVisible
} from 'utils/common';
import { defaultScrollWidth } from 'components/common/TrendingScroll/constants';
import { ScrolledInfo } from 'types';
import { defaultScrollState } from 'constants/defaults';
import { topCreatorsCount } from 'constants/stores';
import axios from 'axios';

export let getTrendingsCancelToken = axios.CancelToken.source();

const updateInitialLoading = createEvent();
const initialLoading = createStore(false).on(updateInitialLoading, state => !state);

const getTrendings = createEffect({
    handler: async () => {
        try {
            getTrendingsCancelToken = axios.CancelToken.source();
            updateInitialLoading();
            const data = await API.trendings.getTrendings({}, getTrendingsCancelToken.token);
            updateInitialLoading();
            return data || {};
        } catch {
            updateInitialLoading();

            return {};
        }
    }
});

const getTrendingsWithoutVideo = createEffect({
    handler: async () => {
        try {
            updateInitialLoading();
            const data = await API.trendings.getTrendings({});
            updateInitialLoading();

            return data || {};
        } catch {
            updateInitialLoading();

            return {};
        }
    }
});

const hashtags = createStore<YEAY.GetTrendingTagResponse[]>([])
    .on(getTrendings.doneData, (_, { tags }) => tags || [])
    .on(getTrendingsWithoutVideo.doneData, (_, { tags }) => tags || []);
const users = createStore<YEAY.GetTrendingUserResponse[]>([])
    .on(getTrendings.doneData, (_, { users }) => users?.slice(0, 8) || [])
    .on(getTrendingsWithoutVideo.doneData, (_, { users }) => users?.slice(0, 8) || []);
const videos = createStore<YEAY.GetTrendVideosResponse[]>([])
    .on(getTrendings.doneData, (_, { videos }) => videos || [])
    .on(playlistEffects.getVideosByTrending.doneData, (_, { items }) =>
        items?.length
            ? items.map(({ id, streaming, engagement }) => ({
                  videoId: id,
                  thumbnailUrl: streaming?.screenGrabUrl,
                  viewCount: engagement?.views
              }))
            : []
    );

const isAllTrendingHashtagsVisible = createStore(true)
    .on(getTrendings.doneData, _ =>
        isAllHashtagsVisible(hashtags.getState(), commonStores.sizesWindowAdaptive.getState()[0])
    )
    .on(commonEvents.setSizesWindowAdaptive, _ =>
        isAllHashtagsVisible(hashtags.getState(), commonStores.sizesWindowAdaptive.getState()[0])
    );

const trendingHashtagsScrollWidth = createStore(parseInt(defaultScrollWidth))
    .on(commonEvents.setSizesWindowAdaptive, _ =>
        calculateTrendingHashtagsScrollWidth(hashtags.getState(), commonStores.sizesWindowAdaptive.getState()[0])
    )
    .on(getTrendings.doneData, _ =>
        calculateTrendingHashtagsScrollWidth(hashtags.getState(), commonStores.sizesWindowAdaptive.getState()[0])
    );

const setForwardScroll = createEvent<ScrolledInfo>();
const forwardScroll = createStore<ScrolledInfo>(defaultScrollState).on(setForwardScroll, ({ position }, state) => ({
    ...state,
    position: position + state.position
}));

const setHashtagsPercentageScroll = createEvent<number>();
const trendingHashtagsPercentageScroll = createStore(0).on(setHashtagsPercentageScroll, (_, state) => state);

const setCreatorsPercentageScroll = createEvent<number>();
const trendingCreatorsPercentageScroll = createStore(0).on(setCreatorsPercentageScroll, (_, state) => state);

const trendingCreatorsScrollWidth = createStore(parseInt(defaultScrollWidth))
    .on(commonEvents.setSizesWindowAdaptive, _ =>
        calculateTrendingHashtagsScrollWidth(hashtags.getState(), commonStores.sizesWindowAdaptive.getState()[0])
    )
    .on(getTrendings.doneData, _ =>
        calculateTrendingCreatorsScrollWidth(users.getState(), commonStores.sizesWindowAdaptive.getState()[0])
    );

const isAllTrendingCreatorsVisible = createStore(true)
    .on(getTrendings.doneData, _ =>
        isAllCreatorsVisible(users.getState().slice(topCreatorsCount), commonStores.sizesWindowAdaptive.getState()[0])
    )
    .on(commonEvents.setSizesWindowAdaptive, _ =>
        isAllCreatorsVisible(users.getState().slice(topCreatorsCount), commonStores.sizesWindowAdaptive.getState()[0])
    );

const trendingsEvents = {};
const trendingsEffects = {
    getTrendings,
    getTrendingsWithoutVideo,
    setHashtagsPercentageScroll,
    setCreatorsPercentageScroll,
    setForwardScroll
};
const trendingsStores = {
    hashtags,
    users,
    videos,
    initialLoading,
    isAllTrendingHashtagsVisible,
    isAllTrendingCreatorsVisible,
    trendingHashtagsScrollWidth,
    trendingCreatorsScrollWidth,
    trendingHashtagsPercentageScroll,
    trendingCreatorsPercentageScroll,
    forwardScroll
};

export { trendingsEvents, trendingsEffects, trendingsStores };
