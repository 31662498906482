import React, { useState } from 'react';
import { SearchFilters } from 'components/common/SearchFilters';
import { SearchResult } from 'components/common/videoComponents/SearchResult';
import { creatorParameter, searchParameters } from 'components/common/SearchFilters/constants';
import { searchEvents } from 'stores/search';

export const Search = () => {
    const [activeSearchParameter, setActiveSearchParameter] = useState(searchParameters[0]);

    const isCreatorSearch = creatorParameter === activeSearchParameter;

    const onChange = (parameter: string) => {
        setActiveSearchParameter(parameter);
        searchEvents.setDefaultHashtag();
        searchEvents.setDefaultVideo();
        searchEvents.setDefaultCreators();
    };

    return (
        <>
            <SearchFilters
                activeSearchParameter={activeSearchParameter}
                isCreatorSearch={isCreatorSearch}
                onChange={onChange}
            />
            <SearchResult isCreatorSearch={isCreatorSearch} />
        </>
    );
};
