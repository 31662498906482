import {
    usernameTextFontSize,
    usernameWrapperBorderRadius,
    usernameWrapperHeight
} from 'components/common/videoComponents/Video/constants';
import { headerHeight } from 'components/common/videoComponents/VideoHeader/constants';
import { MobileProps } from 'components/common/videoComponents/VideoHeader/types';
import { colors, flexCenter, flexStart, fonts, white, xs } from 'constants/styles';
import styled from 'styled-components';

export const Header = styled.header<MobileProps>`
    position: fixed;
    top: 0;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : headerHeight)};
    ${flexCenter};
    justify-content: space-between;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.3);
    padding: 13px;
    padding-top: 18px;
    z-index: 9;
`;

export const MainMobileHeader = styled.div<MobileProps>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : headerHeight)};
    ${flexCenter};
    background-color: transparent;
    padding: 16px;
    z-index: 9;
`;

export const UsernameWrapper = styled.div`
    ${flexStart};
    align-items: center;
    height: ${usernameWrapperHeight};
    border-radius: ${usernameWrapperBorderRadius};
    background: rgba(0, 0, 0, 0.3);
`;

export const UsernameText = styled.span`
    font-size: ${usernameTextFontSize};
    font-style: normal;
    font-weight: 600;
    line-height: ${usernameTextFontSize};
    letter-spacing: 0.21333329379558563px;
    text-align: right;
    color: ${white};
    margin-left: 8px;
    margin-right: 5px;
`;

interface DescriptionProps {
    color?: string;
}

export const Description = styled.span<DescriptionProps>`
    display: block;
    max-width: 150px;
    margin-right: 12px;
    font-size: 12px;
    font-family: ${fonts.sfuidSemibold};
    color: ${({ color }) => color || colors.white};

    @media (max-width: ${xs}) {
        font-size: 12px;
        margin-right: 7px;
    }
`;

interface LinkAppProps {
    backgroundColor?: string;
    fontWeight?: string;
}

export const LinkApp = styled.a<LinkAppProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    max-width: 80px;
    height: 30px;
    font-family: ${fonts.sfuidSemibold};
    font-size: 12px;
    text-decoration: none;
    border-radius: 30px;
    background-color: ${({ backgroundColor }) => backgroundColor || colors.white};
    color: ${colors.black};
    ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight}` : '400')}

    @media (max-width: ${xs}) {
        font-size: 11px;
    }
`;
