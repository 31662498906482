import xIcon from 'assets/img/x.svg';
import { CustomImg } from 'components/common/CustomImg';
import { InviteContent } from 'components/common/InviteContent';
import { DesktopLoader } from 'components/common/Loader';
import { MobileWarning } from 'components/common/blocks/MobileWarning';
import { AvatarImg } from 'components/common/imageComponents/AvatarImg';
import { VerifiedIcon } from 'components/common/imageComponents/VerifiedIcon';
import { AbsoluteWrapper } from 'components/grid/wrappers/AbsoluteWrapper';
import { Column, FlexGrow, Section } from 'components/grid/wrappers/FlexWrapper';
import { videoUrl } from 'constants/routes';
import { useStore } from 'effector-react';
import { LoadingPage } from 'pages/LoadingPage';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { playlistEffects } from 'stores/playlist';
import { trendingsStores } from 'stores/trendings';
import { userEffects, userStores } from 'stores/user';
import {
    BackgroundWrapper,
    CloseButton,
    InnerWrapper,
    InviteWrapper,
    Text,
    Title,
    UserInfoWrapper,
    VideoCard,
    VideosGrid,
    VideosWrapper,
    Wrapper
} from './styles';

interface Props {
    userId: string;
}

export const ProfilePage: FC<Props> = ({ userId }) => {
    const [inviteIsVisible, setInviteIsVisible] = useState(true);

    const user = useStore(userStores.userProfile);
    const profileIsLoading = useStore(userEffects.getUserProfile.pending);

    const videos = useStore(trendingsStores.videos);
    const videosIsLoading = useStore(playlistEffects.getVideosByTrending.pending);

    const { profileImageUrl, username, bio, isTrusted } = user;

    const closeInvitePopup = () => {
        setInviteIsVisible(false);
    };

    useEffect(() => {
        userEffects.getUserProfile({ userId });
        playlistEffects.getVideosByTrending({ byCreatorId: userId });
    }, [userId]);

    if (profileIsLoading) {
        return <LoadingPage />;
    }

    if (Object.keys(user).length === 0) {
        return (
            <FlexGrow alignCenter justifyCenter flexGrow="2">
                <MobileWarning />
            </FlexGrow>
        );
    }

    return (
        <>
            <Wrapper>
                <InnerWrapper>
                    <UserInfoWrapper>
                        <Section justifyCenter={isMobile} marginBottom="8px">
                            <AvatarImg diameter="76px" src={profileImageUrl} />
                        </Section>

                        <Section alignCenter noWrap justifyCenter={isMobile}>
                            <Title>@{username}</Title>

                            {isTrusted && (
                                <Column marginLeft="8px">
                                    <VerifiedIcon diameter="22px" />
                                </Column>
                            )}
                        </Section>

                        {bio && (
                            <Section justifyCenter={isMobile} marginTop="12px">
                                <Text>{bio}</Text>
                            </Section>
                        )}
                    </UserInfoWrapper>

                    <VideosWrapper>
                        {videosIsLoading ? (
                            <Section alignCenter justifyCenter>
                                <DesktopLoader />
                            </Section>
                        ) : (
                            <VideosGrid>
                                {videos.map(video => (
                                    <VideoCard key={video.videoId} to={`${videoUrl}${video.videoId}`}>
                                        <BackgroundWrapper src={video.thumbnailUrl} />
                                    </VideoCard>
                                ))}
                            </VideosGrid>
                        )}
                    </VideosWrapper>
                </InnerWrapper>
            </Wrapper>
            {!isMobile && inviteIsVisible && (
                <InviteWrapper>
                    <AbsoluteWrapper right="12px" top="12px" zIndex="20">
                        <CloseButton type="button" onClick={closeInvitePopup}>
                            <CustomImg alt="close" height="10px" src={xIcon} width="10px" />
                        </CloseButton>
                    </AbsoluteWrapper>

                    <InviteContent />
                </InviteWrapper>
            )}
        </>
    );
};
