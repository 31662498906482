import { combine, createEffect, createEvent, createStore, forward } from 'effector';
import { createNotifyingEffect, mergeGenericHashtagResponse } from 'utils/common';
import { API } from 'services';
import { Hashtags } from 'types';
import { nanoid } from 'nanoid';
import axios, { CancelTokenSource } from 'axios';

let cancelToken: CancelTokenSource | undefined = axios.CancelToken.source();

const setInitialLoading = createEvent<boolean>();
const initialLoading = createStore(false).on(setInitialLoading, (_, state) => state);
//For set hashtag from search input
const setHashtags = createEvent<string>();
//For searched hashtag (click on hashtag from trending and video player)
const setSearchedHashtag = createEvent<string>();
const deleteHashtags = createEvent<string>();
const setDefaultHashtag = createEvent();
export const hashtags = createStore<Hashtags[]>([])
    .on(setHashtags, (hashtag, value) =>
        hashtag.slice(0).concat([
            {
                id: nanoid(),
                value
            }
        ])
    )
    .on(setSearchedHashtag, (hashtag, value) =>
        hashtag.slice(0).concat([
            {
                id: nanoid(),
                value
            }
        ])
    )
    .on(setDefaultHashtag, _ => [])
    .on(deleteHashtags, (hashtag, hashtagId) => hashtag.filter(({ id }) => id !== hashtagId));

const getVideosByHashtags = createNotifyingEffect({
    handler: async () => {
        try {
            setInitialLoading(true);
            let stateHashtags: YEAY.PlaylistVideoResponse[] = [];
            const hashtagsStore = hashtags.getState();
            for (const { value } of hashtagsStore) {
                const { items } = await API.playlist.queryByFilter({
                    pageIndex: 0,
                    limit: 100,
                    //TODO: pagination
                    byHashtag: value
                });
                stateHashtags = items
                    ? mergeGenericHashtagResponse<YEAY.PlaylistVideoResponse>(stateHashtags, items)
                    : stateHashtags;
            }
            setInitialLoading(false);
            return stateHashtags;
        } catch {
            setInitialLoading(false);

            return [];
        }
    }
});

const setDefaultVideo = createEvent();

const searchVideos = createStore<YEAY.PlaylistVideoResponse[]>([])
    .on(getVideosByHashtags.doneData, (_, items) => items)
    .on(setDefaultVideo, _ => []);

forward({
    from: [deleteHashtags, setSearchedHashtag],
    to: getVideosByHashtags
});

forward({
    from: setDefaultHashtag,
    to: getVideosByHashtags
});

//
// const setSearchedHashtag = createEvent<string>();
// export const searchedHashtag = createStore('').on(setSearchedHashtag, (_, hashtag) => hashtag);

const getCreatorByUsername = createEffect({
    handler: async (payload: { searchText: string }) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            const { searchText } = payload;
            setInitialLoading(true);
            const { items } = await API.search.search(
                {
                    searchText: `@${searchText}`,
                    pageIndex: 0,
                    limit: 100,
                    returnQueryCount: false
                },
                cancelToken.token
            );
            setInitialLoading(false);

            // return isTrusted ? items?.filter(({ isTrusted }) => isTrusted) : items;
            return items;
        } catch {
            setInitialLoading(false);
            return [];
        }
    }
});

const setDefaultCreators = createEvent();

const creators = createStore<YEAY.SearchResponse[]>([])
    .on(getCreatorByUsername.doneData, (_, data) => data || [])
    .on(setDefaultCreators, _ => []);

setDefaultCreators.watch(_ => cancelToken?.cancel());

const searchStore = combine(initialLoading, searchVideos, creators);
export const searchEffects = { getCreatorByUsername };
export const searchStores = { searchStore, hashtags, creators };
export const searchEvents = {
    setDefaultVideo,
    setDefaultCreators,
    getVideosByHashtags,
    setHashtags,
    deleteHashtags,
    setDefaultHashtag,
    setSearchedHashtag
};
