import * as playlist from './playlist';
import * as trendings from './trendings';
import * as user from './user';
import * as video from './video';
import * as search from './search';

export const API = {
    user,
    playlist,
    video,
    trendings,
    search
};
