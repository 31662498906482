export const inputHeight = '50px';
export const searchPaddingLeft = '60px';
export const searchBorderRadius = '26px';
export const paddingLeftHashtags = '85px';
export const fontWeightSearch = 600;
export const widthSearch = '100%';
export const hashtagMarginBottom = '10px';
export const wrapperHashtagsMarginBottom = '24px';

export const creatorParameter = 'Creators';
export const videosParameter = 'Videos';
export const searchParameters = [videosParameter, creatorParameter];
