import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { userEffects, userStores } from 'stores/user';

export const baseURL = process.env.REACT_APP_API_URL as string;

const yeayAxiosInstance = axios.create();
let axiosErrorCount = 0;
yeayAxiosInstance.defaults.baseURL = baseURL;
yeayAxiosInstance.defaults.method = 'POST';
yeayAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status;
        const data = config.response.data;
        if (status === 500 && axiosErrorCount < 1) {
            axiosErrorCount++;
            userEffects.loadToken(null);
        } else if (status !== 200) {
            axiosErrorCount = 0;
        }
        return Promise.reject(data);
    }
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = yeayAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${userStores.user.getState().token}`
              }
            : {}
    });

    return (request as any) as Promise<T>;
};
