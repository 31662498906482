import React from 'react';
import {
    AbsoluteImg,
    AvatarImg,
    FeaturesWrapper,
    ViewCount,
    Views,
    WrapperCard
} from 'components/layouts/cards/LatestVideoCard/styles';
import defaultImg from 'assets/defaults/default_img.svg';
import playImg from 'assets/img/play.svg';
import { Image } from 'components/common/imageComponents/Image';
import { playImgDiameter, playImgDiameterMobile } from 'components/layouts/cards/LatestVideoCard/constants';
import { useHistory } from 'react-router';
import { Sizes } from 'types';
import { isMobile } from 'react-device-detect';
import { mobileProductModalEvents } from 'stores/modal';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { Row } from 'components/grid/wrappers/FlexWrapper';
import { videoUrl } from 'constants/routes';

interface Props extends YEAY.GetTrendVideosResponse, YEAY.PlaylistSingleVideoResponse, Sizes {}

export const LatestVideoCard: React.FC<Props> = ({ id, viewCount, width, height, thumbnailUrl, profileImageUrl }) => {
    const history = useHistory();
    const handleClick = () => {
        history.push(videoUrl + (id || ''));
        isMobile && mobileProductModalEvents.closeModal();
    };

    return (
        <WrapperCard height={isMobile ? '180px' : height} width={isMobile ? '103px' : width} onClick={handleClick}>
            <AbsoluteImg src={thumbnailUrl || defaultImg} />
            <FeaturesWrapper>
                <Row alignCenter>
                    {profileImageUrl && (
                        <MarginWrapper marginRight="10px">
                            <AvatarImg url={profileImageUrl} />
                        </MarginWrapper>
                    )}
                    <Views>
                        <Image
                            height={isMobile ? playImgDiameterMobile : playImgDiameter}
                            src={playImg}
                            width={isMobile ? playImgDiameterMobile : playImgDiameter}
                        />
                        <ViewCount>{viewCount || '0'}</ViewCount>
                    </Views>
                </Row>
            </FeaturesWrapper>
        </WrapperCard>
    );
};
