import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const StyledRow = styled.div`
    ${flexCenter};
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
`;

export const ButtonText = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.16470590233802795px;
    text-align: left;
`;
